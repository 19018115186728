// Mixin de centrage
@mixin reset_margin {margin: 0; padding: 0;}
@mixin reset_margin_center {margin: 0 auto; padding: 0; text-align: center;}

// Mixin reset UL
@mixin resetUL {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  li {
      margin-left: 0;
      padding-left: 0;
      list-style-image: none;
      list-style-type: none;
  }
}

// Mixin image responsive
@mixin responsive {
  width: 100%;
  max-width: 100%;
  height: auto;
}

// Mixin font pour IE 9 et IE 10
@mixin sassRem ($styleFont, $sizeFont, $familyFont, $lineHeight) {
  font-style: $styleFont;
  font-size: $sizeFont;
  font-family: $familyFont;
  line-height: $lineHeight;
}

// Mixin flex
@mixin goFlex ($flexDirection, $flexWrap, $flexJustify, $flexAlign) {
  // IE Fix
  display: block;
  display: flex;
  flex-direction: $flexDirection;
  flex-wrap: $flexWrap;
  justify-content: $flexJustify;
  align-items: $flexAlign;
}

// Transition
@mixin transition {transition: all 0.3s ease;}

@mixin defineColorRGB($color-name, $value) {
  $red: red($value);
  $green: green($value);
  $blue: blue($value);
  #{$color-name}: unquote("rgb(#{$red}, #{$green}, #{$blue})");
  #{$color-name}-rgb: $red,$green,$blue;
  #{$color-name}-r: $red;
  #{$color-name}-g: $green;
  #{$color-name}-b: $blue;
}

// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function alpha($color, $opacity) {
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-r: var(#{$color+'-r'});
  $color-g: var(#{$color+'-g'});
  $color-b: var(#{$color+'-b'});
  @return rgba($color-r, $color-g, $color-b, $opacity);
}

/*
// Mediaqueries RWD
@mixin responsiveRWD($taille) {
  @if $taille == mobile-only {
    @media (max-width: 599px) {@content;}

  } @else if $taille == tablette-portrait {
    @media (min-width: 600px) {@content;}

  } @else if $taille == tablette-paysage {
    @media (min-width: 900px) {@content;}

  } @else if $taille == desktop {
    @media (min-width: 1200px) {@content;}

  } @else if $taille == desktop-hd {
    @media (min-width: 1800px) {@content;}
  }
}

.exempleRWD {
  padding: 10px;
  @include responsiveRWD(desktop) {padding: 20px;}
}

// A insérer dans l'ordre
@include responsiveRWD(mobile-only) {}
@include responsiveRWD(tablette-portrait) {}
@include responsiveRWD(tablette-paysage) {}
@include responsiveRWD(desktop) {}
@include responsiveRWD(desktop-hd) {}
*/