/*! sanitize.css | CC0 Public Domain | github.com/jonathantneal/sanitize.css */
pre,textarea{overflow:auto}
[hidden],audio:not([controls]),template{display:none}
details,main,summary{display:block}
input[type=number]{width:auto}
input[type=search]{-webkit-appearance:textfield}
input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration{-webkit-appearance:none}
progress{display:inline-block}
small{font-size:75%}
textarea{resize:vertical}
[unselectable]{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
*,::after,::before{box-sizing:inherit;border-style:solid;border-width:0}
*{/*font-size:inherit;*/line-height:inherit;margin:0;padding:0}
::after,::before{text-decoration:inherit;vertical-align:inherit}
:root{-ms-overflow-style:-ms-autohiding-scrollbar;overflow-y:scroll;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;text-size-adjust:100%;box-sizing:border-box;cursor:default;font:16px/1.5 sans-serif;text-rendering:optimizeLegibility}
a{text-decoration:none}
audio,canvas,iframe,img,svg,video{vertical-align:middle}
button,input,select,textarea{background-color:transparent;color:inherit;font-family:inherit;font-style:inherit;font-weight:inherit;min-height:1.5em}
code,kbd,pre,samp{font-family:monospace,monospace}
nav ol,nav ul{list-style:none}
select{-moz-appearance:none;-webkit-appearance:none}
select::-ms-expand{display:none}
select::-ms-value{color:currentColor}
table{border-collapse:collapse;border-spacing:0}
::-moz-selection{background-color:#B3D4FC;text-shadow:none}
::selection{background-color:#B3D4FC;text-shadow:none}
@media screen{[hidden~=screen]{display:inherit}
[hidden~=screen]:not(:active):not(:focus):not(:target){clip:rect(0 0 0 0)!important;position:absolute!important}
}