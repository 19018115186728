:global(.popover) {
  z-index: 15;
  max-width: 70%;
  
  :global(.popover-header) {
    z-index: 15;
    padding: 4px;
    padding-bottom: 2px;
    background-color: lighten(lightgrey, 13%);
    border-bottom: 1px solid lightgrey;
    font-size: 24px;
  }
  :global(.popover-body) {
    padding: 7px;
    pre {
      color: inherit;
      font: inherit;
    }
  }
  :global(.popover-inner) {
    position: relative;
      top: -10px;
    color: black;
    background-color: white;
    border: 1px solid black;
    box-shadow: 8px 8px 9px 0px rgba(0, 0, 0, .3);

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      margin: auto;
      position: absolute;
      bottom: -7px;
      left: 2px;
      border-bottom: 1px solid black;
      border-left: 1px solid black;
      background-color: #fff;
      z-index: 2;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}