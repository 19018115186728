@font-face {
  font-family: 'roboto_light';
  src: url('../fonts/Roboto-Light.eot');
  src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Roboto-Light.woff2') format('woff2'),
       url('../fonts/Roboto-Light.woff') format('woff'),
       url('../fonts/Roboto-Light.ttf') format('truetype'),
       url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_regular';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Roboto-Regular.woff2') format('woff2'),
       url('../fonts/Roboto-Regular.woff') format('woff'),
       url('../fonts/Roboto-Regular.ttf') format('truetype'),
       url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_bold';
  src: url('../fonts/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Roboto-Bold.woff2') format('woff2'),
       url('../fonts/Roboto-Bold.woff') format('woff'),
       url('../fonts/Roboto-Bold.ttf') format('truetype'),
       url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lato_light';
  src: url('../fonts/Lato-Light.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lato_regular';
  src: url('../fonts/Lato-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lato_bold';
  src: url('../fonts/Lato-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'inter_light';
  src: url('../fonts/Inter-Light.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'inter_regular';
  src: url('../fonts/Inter-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'inter_bold';
  src: url('../fonts/Inter-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}
/* Google Font Poppins */
@font-face {
  font-family: 'poppins_light';
  src: url('../fonts/Poppins-Light.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'poppins_regular';
  src: url('../fonts/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'poppins_bold';
  src: url('../fonts/Poppins-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}