.rate {
  width: 175px;
  display: flex;
  flex-direction: row;

  .star {
    background: url('../../../assets/img/emptyStar.svg');
    height: 35px;
    width: 35px;
    background-size: 35px 35px;
    cursor: pointer;
  }

  .starActive {
    background-color: var(--saumonAlt);
    mask: url('../../../assets/img/filledStar.svg');
  }

  &:hover {
    .star {
      background-color: var(--saumonAlt);
      mask: url('../../../assets/img/filledStar.svg');
      &:hover~.star {
        background: url('../../../assets/img/emptyStar.svg');
      }
    }
  }
}

@media (max-width:600px) {
  .rate .star {
    height: 25px;
    width: 25px;
    background-size: 25px 25px;
  }
}