///////////////////////////////////
//// Customisation des styles /////
///////////////////////////////////

:root {
  --saumon: #f47e60;
  --saumonAlt: #f47e60;
  --saumonAlt2: #f47e60;
  --saumonAlt3: #f47e60;
  --saumonAlt4: #f47e60;
  --saumonStart: #f47e60;
  --saumonAlt5: #f47e60;

  --saumon-r: 244;
  --saumon-g: 126;
  --saumon-b: 96;

  --saumon0: #f47e60;
  --saumon1: #f6865b;
  --saumon2: #f78f55;
  --saumon3: #f79851;
  --saumon4: #f6a24d;
  --saumon5: #f8ecac;
  --saumon6: #ffee89;

  --gris-degrade1: #f3f3f3;
  --gris-degrade2: #f9f9f9;

  --ocre: #7d2008;
  --ocreAlt: #7d2008;
  --ocreAlt2: #7d2008;
  --ocreAlt3: #7d2008;
  --ocreAlt4: #7d2008;
  --ocreAlt5: #7d2008;

  --pdtLib: #f47e60;
  --pdtCode: rgba(var(--saumon-r), var(--saumon-g), var(--saumon-b), 0.85);
  --pdtPrix: rgba(var(--saumon-r), var(--saumon-g), var(--saumon-b), 0.7);

  --txt: white;
  --txtAlt: var(--ocre);
  --txtSpe: var(--saumon);
  --txtRubrique: var(--saumon);
  --txtSpeAlt: var(--ocre);
  --txtBottom: white;

  --choixHome: linear-gradient(45deg, var(--saumon0) 0%, var(--saumon5) 94%, var(--saumon5) 100%);
  --choixHomeLine: white;
  --topBarre: white;
  --topBarrePadding: 2px 10px;

  --devisTotalBg: var(--ocre);
  --devisTxt: var(--txtSpe);
  --devisBg: var(--txtSpeAlt);
  --devisTxtAlt: var(--txtSpeAlt);
  --devisBgAlt: var(--txtSpe);

  --mobileDrawerBg: var(--ocre);

  // Typos
  --typo_light: 'roboto_light'; /*'poppins_light';*/
  --typo_regular: 'roboto_regular'; /*'poppins_regular';*/
  --typo_bold: 'roboto_bold'; /*'poppins_bold';*/

  // Froid
  --froid: #60aff4;
}

// Components
@import 'components/fonts';
@import 'components/mixins';
@import 'components/buttons';

// Couleurs
$bleu: #60aff4;
$gris: #f2f2f2;
$gris_moyen: #b8b8b8;
$gris_fonce: #424242;
$chaud: #d27a59;
$chaud_pacific: #e52e39;
$froid: #9dcaf1;
$froid_thermor: #a7d6f4;
$froid_pacific: #008aac;

@import 'styles_or';

///////////////////////////////////
////// Styles CSS généraux ////////
///////////////////////////////////

* {
  @include reset_margin;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body,
#root {
  position: relative;
  height: 100%;
  background: linear-gradient(310deg,
      white 0%,
      white 30%,
      var(--gris-degrade1) calc(30% + 1px),
      var(--gris-degrade1) 40%,
      var(--gris-degrade2) calc(40% + 1px),
      var(--gris-degrade2) 55%,
      white calc(55% + 1px),
      white 100%);
}
iframe
{
    display: none;
}
nav,
form,
ul,
li,
p {
  @include reset_margin;
}

/* p {white-space: nowrap;} nécessaire pour le navigateur samsung */
p,
span {
  margin: 0;
  padding: 0;
  white-space: normal;
  line-height: 130%;
}

hr {
  margin: 8px 0;
}

sup,
sub {
  font-size: 70%;
}

button {
  @include goFlex(row, wrap, center, center);
  border: none;
  outline: none;
  background: none;
  font-size: 1rem;
  vertical-align: middle;
  transition: 0.3s;

  &::before,
  &::after {
    content: '';
    flex: 1 0 auto;
  }

  &:hover,
  &:active {
    transition: 0.3s;
  }
}

button.help {
  &.cold {
    background-color: var(--froid);
  }
}

fieldset {
  outline: none;
  border: none;
}

select {
  padding-right: 25px !important;
}

input,
select {
  border-radius: 0 !important;
  /*outline: none !important; box-shadow: none !important;*/
}

input:focus,
select:focus {
  color: none !important;
  /*outline: none !important; box-shadow: none !important;*/
}

.main.container-fluid {
  @include reset_margin;
}

.main.container-fluid>.row {
  @include reset_margin;
}

.main.container-fluid>.row>div {
  @include reset_margin;
}

// Zoom
.zoom {
  transition: 0.3s;
  transition-duration: 0.3s;
  transform: scale(1);
}

.zoom:hover {
  transform: scale(1.1);
}

// W100 + Row
// .w100 {position: relative; width: 100%; max-width: 100%;}
// .row {@include reset_margin_center;	@include goFlex(row, wrap, flex-start, flex-start);	width: 100%; max-width: 100%;}
// @include goFlex(row, wrap, center, center);
// @include sassRem (normal, 1rem, var(--typo_regular), 130%);
// <img src="default.png" srcset="small.png 320w, medium.png 640w, large.png 1024w" alt="" />

///////////////////////////////////
///////////// Styles //////////////
///////////////////////////////////
#root {

  .pageHome,
  .pageChoix {
    position: relative;
    @include goFlex(column, wrap, center, center);
    height: 100%;
    background: url(../img/background_1920.jpg) no-repeat;
    background-position: center;
    background-size: cover;

    .header {
      @include goFlex(row, wrap, flex-end, center);
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px 20px;

      i.pictoHamburger {}

      .choixLangue {
        @include goFlex(row, wrap, flex-end, center);
        margin-bottom: 5px;
        width: 100%;
      }

      button.langue.langueChoix {
        color: var(--txt);
      }

      button.langue.langueChoix:hover,
      button.langue.langueChoix:active {
        color: var(--saumonAlt);
      }
    }

    .main {
      &.container-fluid {
        padding-bottom: 0;
      }

      .blocHome {
        position: relative;
        @include goFlex(row, wrap, center, center);
        @include reset_margin_center;
        background-color: rgba(var(--saumonStart), 0.5);
        width: 440px;

        .blocHomeTitre {
          padding: 15px;
          width: 100%;
          text-align: left;

          a.btn {
            @include sassRem(normal, 1.125rem, var(--typo_light), 100%);
            color: white;
            text-transform: uppercase;
            border: 1px solid white;
            padding: 20px;
            width: 100%;
            letter-spacing: 0px;
            transition: 0.3s;
            outline: none !important;
            white-space: normal;

            &:hover,
            &:active {
              background-color: rgba(white, 0.3);
              font-family: var(--typo_regular);
              transform: scale(1.03);
              transition: 0.3s;
            }
          }

          a.createAccount {
            color: white;
            text-decoration: underline;
            font-size: 0.825em;
          }
        }

        .blocHomeTitrePJP {
          a.btn {
            @include sassRem(normal, 1.125rem, var(--typo_bold), 100%);
            color: white;
            border: none;
            background-color: var(--saumonAlt);

            &:hover,
            &:active {
              background-color: var(--saumonAlt);
              font-family: var(--typo_bold);
            }
          }
        }

        .blocHomeTitrePacific {
          a.btn {
            @include sassRem(normal, 1rem, var(--typo_bold), 100%);
            color: black;
            border: 1px solid black;
            background-color: none;

            &:hover,
            &:active {
              background-color: none;
              font-family: var(--typo_bold);
            }
          }
        }

        .blocProjipac {
          position: absolute;
          top: -115px;
          right: 0;
          @include goFlex(row, wrap, center, center);

          p {
            @include sassRem(normal, 2.25rem, var(--typo_light), 100%);
            color: var(--txt);
            padding-top: 45px;
          }

          p.pacificText {
            @include sassRem(normal, 2rem, var(--typo_regular), 100%);
            color: var(--txt);
            padding-right: 20px;
            padding-top: 10px;
          }

        }

        .blocProjipacPacific {
          position: absolute;
          top: -140px;
          right: 0;
          @include goFlex(row, wrap, center, center);
        } 

        .blocSimulhome {
          top: -257px;
          right: 30px;
          background-color: white;
        }

        p.BlocHomeTexte {
          @include sassRem(normal, 1rem, var(--typo_light), 170%);
          color: white;
          text-align: left;
          background: var(--saumonStart);
          padding: 20px 30px;
        }
        p.pacific {
          background: linear-gradient(310deg, #008AAC 30%, #E52E39 70%);
        }
      }

      .choixProjet {
        position: relative;
        @include goFlex(column, wrap, center, center);
        @include reset_margin_center;
        //linear-gradient(45deg, #f47e60 0%, #f8ecac 94%, #f8ecac 100%);
        background: var(--choixHome);
        width: 100%;
        max-width: 500px;
        margin-right: 10%;

        .blocProjipac {
          @include goFlex(row, wrap, center, center);
          padding: 30px 0;
          text-align: center;

          p {
            @include sassRem(normal, 2.25rem, var(--typo_light), 100%);
            color: white;
            padding-top: 45px;
          }

          .pacificText {
            padding-top: 10px;
            padding-right: 20px;
          }
          
        }

        .blocProjet {
          @include goFlex(row, wrap, center, center);
          padding: 20px 0 80px 0;

          .nouveauP,
          .simulationP {
            position: relative;
            @include goFlex(column, wrap, center, center);
            border: 2px solid var(--choixHomeLine);
            margin: 0 20px;
            padding: 20px 30px;
            min-height: 190px;
            min-width: 187px;

            &::before,
            &::after {
              content: '';
              flex: 1 0 auto;
            }

            p {
              display: block;
              margin-top: 15px;
              @include sassRem(normal, 1.125rem, var(--typo_light), 100%);
              color: var(--choixHomeLine);
              text-transform: uppercase;
              transition: 0.3s;
            }

            strong {
              display: block;
              margin-top: -25px;
              font-size: 6rem;
              color: var(--choixHomeLine);
              line-height: 100%;
              transition: 0.3s;
            }

            img {
              display: block;
              margin-top: 10px;
              transition: 0.3s;
            }

            &:hover,
            &:active {
              background-color: rgba(white, 0.3);
              transition: 0.3s;
            }
          }

          .simulationP p {
            margin-top: 5px;
          }

          .nouveauP:hover,
          .nouveauP:active {

            // p {margin-top: 20px; font-family: roboto_regular; transition: 0.3s;}
            // strong {margin-top: -135px; transition: 0.3s;}
            p {
              transform: translateY(60px);
              font-family: var(--typo_regular);
              transition: 0.3s;
            }

            strong {
              transform: translateY(-50px);
              transition: 0.3s;
            }
          }

          .simulationP:hover,
          .simulationP:active {

            // p {margin-top: 50px; font-family: var(--typo_regular) ; transition: 0.3s;}
            // img {margin-top: -130px; transition: 0.3s;}
            p {
              transform: translateY(95px);
              font-family: var(--typo_regular);
              transition: 0.3s;
            }

            img {
              transform: translateY(-45px);
              transition: 0.3s;
            }
          }
        }
      }

      .pacific {
        background: linear-gradient(310deg, #008AAC 40%, #E52E39 60%);
      }

      .afterInfo{
        top: 0 !important;
      }
    }
  }

  .pageChoix {
    .main .pacific {
     //margin: 0 10%
    //margin: 0 0
    }
  }

  .pageHomeThermor,
  .pageChoixThermor {
    height: 100%;
    background: url(../img/background_thermor_1920.jpg) no-repeat;
    background-position: center;
    background-size: cover;
  }

  .pageHomePacific,
  .pageChoixPacific {
    background-image: none;
  }

  .pageAide {
    //height: 100%;
    //padding-bottom: 123px;

    .header {
      margin-bottom: 0;
    }

    .main {
      &.container-fluid {
        padding-bottom: 0;
        max-width: 100%;
      }

      @include goFlex(column, wrap, flex-start, flex-start);
      height: 100%;
      background: url(../img/background_1920.jpg) no-repeat;
      background-position: center;
      background-size: cover;
      padding: 30px 0;

      &.pacific {
        background: none;
      }

      .blocAide {
        @include goFlex(row, wrap, flex-start, flex-start);
        @include reset_margin_center;
        width: 80%;
        padding: 30px 0;
        background: rgba(white, 0.9);

        .sidebar {
          @include goFlex(column, wrap, flex-start, flex-start);
          width: 100%;
          padding: 30px 20px;

          nav.insideMenu {
            margin: 20px 0;

            ul {
              @include resetUL;
              @include goFlex(column, wrap, flex-start, flex-start);
              font-size: 0;

              li {
                margin: 5px 0;

                a {
                  width: 100%;
                  @include goFlex(row, wrap, center, center);
                  @include sassRem(normal, 0.875rem, var(--typo_light), 100%);
                  color: black;
                  text-decoration: none;
                  text-align: left;
                  border-bottom: 5px solid transparent;
                  transition: 0.3s;
                }

                &:hover a,
                &:active a,
                &.active a {
                  border-bottom: 5px solid var(--saumonAlt);
                  text-decoration: none;
                  transition: 0.3s;
                }
              }
            }
          }
        }

        .contentAide {
          @include goFlex(column, wrap, flex-start, flex-start);
          padding: 20px 20px 0 20px;
          height: 100vh;

          p {
            width: 100%;
            padding-bottom: 5px;
            border-bottom: 5px solid var(--saumonAlt);
            @include sassRem(normal, 1.5rem, var(--typo_bold), 100%);
            color: black;
            text-align: left;
          }

          .helpMsg {
            position: absolute;
            bottom: 0;
          }

          video {
            width: 475px;
          }

          .blocVideos {
            @include goFlex(row, wrap, flex-start, flex-start);
            margin: 20px 0 40px 0;

            .laVideo {
              position: relative;

              &:last-child iframe {
                padding-right: 0;
              }

              iframe {
                max-width: 100%;
                max-height: 260px;
                padding-right: 30px;
              }

              button.btn_play {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -20px;
                margin-left: -20px;
                background: url(../img/picto-play.svg) no-repeat;
                width: 40px;
                height: 40px;
                display: none;
              }
            }
          }
        }
      }
    }

    .footer {
      margin-top: 0;
    }
  }

  // Header
  .header {
    position: relative;
    margin-bottom: 20px;

    button.langue {
      &.langueChoix {
        display: block !important;
        @include sassRem(normal, 0.875rem, var(--typo_regular), 100%);
        color: var(--saumon);
        text-transform: uppercase;
      }

      span {
        display: inline-block;
        background: url(../img/drapeaux/drapeau-fr.svg) no-repeat;
        width: 21px;
        height: 13px;
        margin-right: 6px;

        &.langueAU {
          background: url(../img/drapeaux/drapeau-au.svg) no-repeat;
        }

        &.langueBE {
          background: url(../img/drapeaux/drapeau-be.svg) no-repeat;
        }

        &.langueBO {
          background: url(../img/drapeaux/drapeau-bo.svg) no-repeat;
        }

        &.langueCR {
          background: url(../img/drapeaux/drapeau-cr.svg) no-repeat;
        }

        &.langueCZ {
          background: url(../img/drapeaux/drapeau-cz.svg) no-repeat;
        }

        &.langueDE {
          background: url(../img/drapeaux/drapeau-de.svg) no-repeat;
        }

        &.langueDA {
          background: url(../img/drapeaux/drapeau-da.svg) no-repeat;
        }

        &.langueES {
          background: url(../img/drapeaux/drapeau-es.svg) no-repeat;
        }

        &.langueET {
          background: url(../img/drapeaux/drapeau-et.svg) no-repeat;
        }

        &.langueFI {
          background: url(../img/drapeaux/drapeau-fi.svg) no-repeat;
        }

        &.langueFR {
          background: url(../img/drapeaux/drapeau-fr.svg) no-repeat;
        }

        &.langueGB {
          background: url(../img/drapeaux/drapeau-gb.svg) no-repeat;
        }

        &.langueEN {
          background: url(../img/drapeaux/drapeau-gb.svg) no-repeat;
        }

        &.langueEL {
          background: url(../img/drapeaux/drapeau-el.svg) no-repeat;
        }

        &.langueHO {
          background: url(../img/drapeaux/drapeau-ho.svg) no-repeat;
        }

        &.langueIR {
          background: url(../img/drapeaux/drapeau-ir.svg) no-repeat;
        }

        &.langueIT {
          background: url(../img/drapeaux/drapeau-it.svg) no-repeat;
        }

        &.langueLT {
          background: url(../img/drapeaux/drapeau-lt.svg) no-repeat;
        }

        &.langueNL {
          background: url(../img/drapeaux/drapeau-nl.svg) no-repeat;
        }

        &.langueNO {
          background: url(../img/drapeaux/drapeau-no.svg) no-repeat;
        }

        &.languePL {
          background: url(../img/drapeaux/drapeau-pl.svg) no-repeat;
        }

        &.languePO {
          background: url(../img/drapeaux/drapeau-po.svg) no-repeat;
        }

        &.langueSD {
          background: url(../img/drapeaux/drapeau-sd.svg) no-repeat;
        }

        &.langueSV {
          background: url(../img/drapeaux/drapeau-sv.svg) no-repeat;
        }

        &.langueSI {
          background: url(../img/drapeaux/drapeau-si.svg) no-repeat;
        }

        &.langueSK {
          background: url(../img/drapeaux/drapeau-sk.svg) no-repeat;
        }

        &.langueSL {
          background: url(../img/drapeaux/drapeau-sl.svg) no-repeat;
        }

        &.langueSU {
          background: url(../img/drapeaux/drapeau-su.svg) no-repeat;
        }

        &.langueVLS {
          background: url(../img/drapeaux/drapeau-nl.svg) no-repeat;
        }

        &.languePT {
          background: url(../img/drapeaux/drapeau-pt.svg) no-repeat;
        }

        &.langueBG {
          background: url(../img/drapeaux/drapeau-bg.svg) no-repeat;
        }

        &.langueSR {
          background: url(../img/drapeaux/drapeau-sr.svg) no-repeat;
        }

        &.langueHU {
          background: url(../img/drapeaux/drapeau-hu.svg) no-repeat;
        }

        &.langueHR {
          background: url(../img/drapeaux/drapeau-hr.svg) no-repeat;
        }

        &.langueRO {
          background: url(../img/drapeaux/drapeau-ro.svg) no-repeat;
        }
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 19;
      background: rgba(0, 0, 0, 0.8);
      height: 100vh;
      display: none;
    }

    .menuMobile {
      @include goFlex(column, wrap, flex-start, center);
      position: fixed;
      top: 0;
      left: -300px;
      bottom: 0;
      z-index: 20;
      height: 100vh;
      width: 100%;
      max-width: 300px;
      padding: 20px 0;
      background: var(--mobileDrawerBg);
      left: -300px;
      transition-duration: 500ms;

      .blocProjipac {
        @include goFlex(row, wrap, center, center);

        p {
          @include sassRem(normal, 1.75rem, var(--typo_light), 100%);
          color: white;
          padding-top: 27px;
        }
      }

      nav {
        padding: 20px 0 10px 0;
        width: 100%;

        ul {
          @include resetUL;
          @include goFlex(column, wrap, flex-start, flex-start);
          font-size: 0;

          li {
            width: 100%;

            a {
              width: 100%;
              @include goFlex(row, wrap, center, center);
              @include sassRem(normal, 0.875rem, var(--typo_regular), 100%);
              color: white;
              text-transform: uppercase;
              padding: 10px 5px;
              transition: 0.3s;
              background: var(--saumonAlt);
              border-bottom: 1px solid var(--ocreAlt);

              &:hover,
              &:active,
              &.active {
                background: white;
                color: var(--ocre);
                text-decoration: none;
                transition: 0.3s;
              }
            }

            &.menuAide {
              a i {
                display: inline-block;
                background-color: white;
                mask: url(../img/picto-menu-aide.svg) no-repeat;
                width: 18px;
                height: 18px;
                margin-right: 6px;
              }

              &:hover,
              &:active {
                a i {
                  background-color: var(--ocre);
                  mask: url(../img/picto-menu-aide-on.svg) no-repeat;
                }
              }
            }

            &.menuCompte {
              a i {
                display: inline-block;
                background-color: white;
                mask: url(../img/picto-menu-compte.svg) no-repeat;
                width: 16px;
                height: 16px;
                margin-right: 6px;
              }

              &:hover,
              &:active {
                a i {
                  background-color: var(--ocre);
                  mask: url(../img/picto-menu-compte-on.svg) no-repeat;
                }
              }
            }

            &.menuSimulations {
              a i {
                display: inline-block;
                background-color: white;
                mask: url(../img/picto-menu-simulations.svg) no-repeat;
                width: 15px;
                height: 12px;
                margin-right: 8px;
              }

              &:hover,
              &:active {
                a i {
                  background-color: var(--ocre);
                  mask: url(../img/picto-menu-simulations-on.svg) no-repeat;
                }
              }
            }
          }
        }
      }
    }

    .mobileMenuOpen {
      .overlay {
        display: block;
      }

      .menuMobile {
        left: 0;
      }
    }

    .topBarre {
      @include goFlex(row, wrap, flex-end, center);
      width: 100%;
      padding: var(--topBarrePadding);
      background: var(--topBarre);

      button.btn-menuMobile {
        position: absolute;
        top: 9px;
        left: 10px;
        @include reset_margin;
        background-color: var(--txtSpe);
        mask: url(../img/picto-hamburger.svg) no-repeat;
        width: 18px;
        height: 14px;
        transition: none;
        display: none;

        &::before,
        &::after {
          content: '';
          flex: 1 0 auto;
          display: none;
        }

        &:hover,
        &:active {
          background: var(--ocre);
          mask: url(../img/picto-hamburger-on.svg) no-repeat;
          transition: none;
        }
      }

      nav.topMenu {
        margin-right: 25px;

        ul {
          @include resetUL;
          @include goFlex(row, wrap, center, center);
          font-size: 0;

          li {
            margin: 0;
            padding: 5px 30px;

            a {
              width: 100%;
              @include goFlex(row, wrap, center, center);
              @include sassRem(normal, 0.875rem, var(--typo_regular), 100%);
              color: var(--txtSpe);
              text-transform: uppercase;
              transition: 0.3s;

              &:hover,
              &:active {
                color: var(--ocre);
                text-decoration: none;
                transition: 0.3s;
              }
            }

            &.menuAide {
              a i {
                display: inline-block;
                background: url(../img/picto-menu-aide.svg) no-repeat;
                width: 18px;
                height: 18px;
                margin-right: 6px;
              }

              &:hover,
              &:active {
                a i {
                  background: url(../img/picto-menu-aide-on.svg) no-repeat;
                }
              }
            }

            &.menuCompte {
              a i {
                display: inline-block;
                background: url(../img/picto-menu-compte.svg) no-repeat;
                width: 16px;
                height: 16px;
                margin-right: 6px;
              }

              &:hover,
              &:active {
                a i {
                  background: url(../img/picto-menu-compte-on.svg) no-repeat;
                }
              }
            }

            &.menuCompteThermor {

              &:hover,
              &:active {
                background-color: black;
              }
                
              padding: 15px 20px;

              a i {
                display: inline-block;
                background-color: var(--txtSpe);
                mask: url(../img/picto-menu-compte-thermor.svg) no-repeat;
                width: 19px;
                height: 18px;
                margin-right: 10px;
              }

              a {
                &:hover,
                &:active {
                  color: white;
                  text-decoration: none;
                  transition: 0;
                }
              }
            }

            &.menuSimulations {
              a i {
                display: inline-block;
                background: url(../img/picto-menu-simulations.svg) no-repeat;
                width: 15px;
                height: 12px;
                margin-right: 10px;
              }

              &:hover,
              &:active {
                a i {
                  background: url(../img/picto-menu-simulations-on.svg) no-repeat;
                }
              }
            }

            &.menuSimulationsThermor {

              &:hover,
              &:active {
                background-color: black;
              }

              padding: 15px 20px;

              a i {
                display: inline-block;
                background-color: var(--txtSpe);
                mask: url(../img/picto-menu-simulations-thermor.svg) no-repeat;
                width: 14px;
                height: 18px;
                margin-right: 10px;
              }

              a {
                &:hover,
                &:active {
                  color: white;
                  text-decoration: none;
                  transition: 0;
                }
              }
            }

            &.menuDeconnexion {
              // margin: 0;

              a i {
                display: inline-block;
                background-color: var(--txtSpe);
                mask: url(../img/picto-deconnexion.svg) no-repeat;
                width: 15px;
                height: 15px;
                margin-right: 8px;
              }

              &:hover,
              &:active {
                a i {
                  background-color: var(--ocre);
                  mask: url(../img/picto-deconnexion-on.svg) no-repeat;
                }
              }
            }

            &.menuSubThermor {

              &:hover,
              &:active {
                background-color: black;
              }

              padding: 15px 20px;

              a i {
                display: inline-block;
                background-color: var(--txtSpe);
                mask: url(../img/picto-menu-thermor.svg) no-repeat;
                width: 18px;
                height: 18px;
              }

              a {
                &:hover,
                &:active {
                  color: white;
                  text-decoration: none;
                  transition: 0;
                }
              }
            }
          }
        }
      }
    }

    .menuSubThermorCell {
      display: flex; 
      justify-content: center;
      align-items: center;
      padding: 10px 0px;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      p {
        margin-top: 5px;
        color: white;
      }

      a {
        text-decoration: none;
      }
    }

    .menuSubThermorCellActive {
      &:hover {
        background-color: black;
      }
    }

    .menuUserThermorCell {

      width: 80%;
      margin: 10px 10px 10px 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid white;

      &:hover {
        border: 1px solid black;
      }

      a {
        color: black;

        &:hover,
        &:active {
          color: black;
          text-decoration: none;
          transition: 0;
        }
      }
    }

    .bandeauThermorContainer {
      @include goFlex(row, wrap, center, center);
      width: 100%;
      background-color: white;
    }

    .bandeauThermor {
      @include goFlex(row, wrap, left, center);
      padding-top: 15px;
      min-width: 1200px;

      a {
        &:hover, &:active, &visited {
          text-decoration: none;
        }
      } 

      .simulHomeLibelle {
        padding-left: 20px;
        color: var(--saumonAlt);
        font-weight: bold;
        font-size: 16px;
      }

      .helpThermorContainer {
        @include goFlex(row, wrap, flex-end, center);
        flex: 1;
      }

      .helpThermor {
        color: black;
        font-weight: bold;
        font-size: 16px;
        margin-right: 20px;
      }
    }

    .thermorReduceMode {
      display: none;
      color: var(--saumon);
    }

    .menuBarreContainer {
      @include goFlex(row, wrap, center, center);
      background-image: linear-gradient(to top, var(--saumon0), var(--saumon1), var(--saumon2), var(--saumon3), var(--saumon4));
      width: 100%;
    }

    .menuBarre {
      @include goFlex(row, wrap, center, center);
      //padding: 0 20px;
      min-width: 1200px;

      .logoProjipac {
        margin: 15px 0;

        .logoPacificImg {
          filter: drop-shadow(2px 2px 1mm #666);
          margin: 15px 15px 15px 0px
        }
      }

      .logoProjipac p {
        display: inline-block;
        position: relative;
        top: 8px;
        margin-left: 10px;
        font-style: normal;
        font-size: 1.75rem;
        font-family: var(--typo_light);
        line-height: 100%;
        color: var(--txt);
        text-transform: uppercase;
      }

      .menuProjipac {
        padding: 0 20px;

        nav.mainMenu {
          margin: 0 10px;

          ul.chevronsBarre {
            @include resetUL;
            display: inline-block;
            font-size: 0;

            li {
              display: inline-block;

              /*
					    &:first-child a {
					      padding-left: 15px;
					      &:before {border: none;}
					    }
					    */

              /*
					    &:last-child a {
					      padding-right: 15px;
					      margin-right: 0;
					      &:after {border: none;}
					    }
					    */

              a {
                position: relative;
                display: block;
                height: 92px;
                margin-right: 10px;
                padding: 0 6px 0 6px;
                @include sassRem(normal, 1rem, var(--typo_bold), 120%);
                color: var(--txt);
                text-decoration: none;
                line-height: 92px;
                text-align: center;
                text-transform: uppercase;
                background: transparent;

                &.passed {
                  color: var(--txtAlt);
                }

                &:before,
                &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  border: 0 solid transparent;
                  border-width: 46px 10px;
                  width: 0;
                  height: 0;
                }

                &:before {
                  left: -13px;
                  border-left-color: transparent;
                }

                &:after {
                  left: 100%;
                  border-color: transparent;
                  border-left-color: transparent;
                }

                &:hover,
                &:active,
                &.active {
                  background: var(--ocre);
                  color: var(--saumon);

                  &:before {
                    border-color: var(--ocre);
                    border-left-color: transparent;
                  }

                  &:after {
                    border-left-color: var(--ocre);
                  }
                }

                &.active {
                  color: white;
                }
              }
            }
          }

          ul.chevronsBarreThermor {
            li {
              a {
                padding: 0 0px 0 6px;

                &:before {
                  left: 0px;
                  border-width: 46px 0px;
                }

                &:hover {
                  background: black;
                  color: white;

                  &:after {
                    border-left-color: black;
                  }
                }
              }
            }
          }

          ul.chevronsBarrePacific {
            li {
              a {
                @include sassRem(normal, 1rem, var(--typo_regular), 120%);
                color: var(--txt);
                line-height: 92px;

                &:before, &:after {
                  border: none;
                }

                &:active, &.active {
                  background: none;
                  color: var(--ocre);
                  @include sassRem(normal, 1rem, var(--typo_bold), 120%);
                  line-height: 92px;
                }

                &:hover {
                  background: none;
                }
              }
            }
          }

        }
      }
    }

    .bandeEtapes {
      @include goFlex(row, wrap, center, center);
      justify-content: space-between !important;
      width: 100%;
      padding: 12px;
      background: var(--ocre);
      display: none;

      p {
        @include sassRem(normal, 1rem, var(--typo_light), 100%);
        color: white;
        margin: 0 5px;

        strong {
          margin-left: 4px;
        }
      }

      button.etapePREV {
        display: block;
        background: url(../img/picto-prev.svg) no-repeat;
        width: 25px;
        height: 25px;
        margin-right: 6px;

        &:hover,
        &:active {
          background: url(../img/picto-prev-on.svg) no-repeat;
          transition: none;
        }
      }

      button.etapeNEXT {
        display: block;
        background: url(../img/picto-next.svg) no-repeat;
        width: 25px;
        height: 25px;
        margin-left: 6px;

        &:hover,
        &:active {
          background: url(../img/picto-next-on.svg) no-repeat;
          transition: none;
        }
      }
    }
  }

  // Main
  .main {
    @include reset_margin;

    &.container-fluid {
      padding-bottom: 100px;
      max-width: 1200px;
      margin: auto;
    }

    .devis {
      padding: 0 30px;

      .choixTVA {
        @include goFlex(row, wrap, flex-end, center);
        width: 100%;

        form {
          @include reset_margin;

          select {
            @include reset_margin;
            height: 36px;
            padding-left: 10px;
            padding-right: 25px;
            min-width: 200px;
          }
        }
      }

      .devisTVA {
        @include goFlex(row, wrap, flex-start, stretch);
        width: 100%;
        margin-top: 10px;
        border-left: 1px solid var(--saumon);
        border-right: 1px solid var(--saumon);
        border-top: 5px solid var(--saumon);
        border-bottom: 5px solid var(--saumon);

        .produitTAB {
          width: 100%;
          @include goFlex(row, wrap, flex-start, stretch);

          .imageTAB {
            padding: 30px;
            border-right: 1px solid var(--saumon);
            width: 100%;
            max-width: 280px;

            img {
              @include responsive;
            }

            .pictoNRJ {
              height: 60px;
              width: auto;
              margin: 0 auto 0 auto;
              margin-bottom: 0;
            }
          }

          .tarifTAB {
            @include goFlex(row, wrap, flex-start, flex-start);
            flex: 1;
            padding: 20px 0;
            width: 100%;

            input {
              @include reset_margin;
              padding-top: 0 !important;
              text-align: right;
            }

            input~span.unit {
              /*position: relative !important;right:0;*/
              top: 1px !important;
            }

            em {
              margin-bottom: 10px;
              padding: 0 5px;
              width: 100%;
              @include sassRem(normal, 1.25rem, var(--typo_bold), 100%);
              color: var(--saumonAlt4);
            }

            table.prodTAB,
            table.accessTAB {
              flex-basis: 100%;

              thead tr th {
                padding: 10px 5px;
                border: 1px solid var(--saumon);
                @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
                font-weight: normal;

                &:first-child {
                  border-left: 0;
                }

                &:last-child {
                  border-right: 0;
                }
              }

              tbody tr {
                background: $gris;

                td {
                  position: relative;
                  padding: 10px 5px;
                  border: 1px solid var(--saumon);
                  @include sassRem(normal, 0.875rem, var(--typo_regular), 100%);
                  line-height: 38px;

                  .blocForm {
                    margin-bottom: 0;
                  }

                  &:first-child {
                    border-left: 0;
                  }

                  &:last-child {
                    border-right: 0;
                  }

                  &.code {
                    width: 10%;
                    text-align: right;
                  }

                  &.libelle {
                    width: auto;
                  }

                  &.quantite {
                    width: 10%;
                  }

                  &.prix {
                    width: 10%;
                    text-align: right;
                  }

                  &.remise {
                    width: 10%;
                  }

                  &.ecopart {
                    width: 10%;
                    text-align: right;
                  }

                  &.total {
                    width: 10%;
                    text-align: right;
                  }

                  &.delete {
                    width: 30px;
                  }
                }
              }

              tbody tr:nth-child(odd) {
                background: white;
              }

              tbody tr:nth-child(even) {
                background: $gris;
              }

              tbody tr td {
                padding: 5px;
              }

              button.btn_delete {
                position: relative;
                @include goFlex(row, wrap, flex-end, center);
                transition: none;

                i {
                  display: inline-block;
                  background-color: var(--saumon);
                  mask: url(../img/picto-delete.svg) no-repeat;
                  width: 17px;
                  height: 21px;
                  margin-right: 6px;
                  transition: none;
                }

                span {
                  display: none;
                  @include reset_margin;
                  @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
                  color: white;
                  text-align: center;
                  padding: 4px 12px;
                  background: var(--saumon);
                  transition: 0.3s;

                  &:hover,
                  &:active {
                    background: var(--ocre);
                    transition: 0.3s;
                  }
                }

                &:hover i,
                &:active i {
                  background: var(--ocre);
                  mask: url(../img/picto-delete-on.svg) no-repeat;
                  transition: none;
                }
              }
            }

            .tab_sousTotal,
            .tab_total {
              @include goFlex(row, wrap, flex-end, center);
              border-bottom: 1px solid var(--saumon);
              width: 100%;
              margin-bottom: 20px;

              p {
                @include sassRem(normal, 1rem, var(--typo_regular), 100%);
                text-transform: uppercase;
                text-align: right;
              }

              span {
                min-width: 120px;
                background: var(--saumon);
                margin-left: 10px;
                padding: 8px 0;
                @include sassRem(normal, 1rem, var(--typo_bold), 100%);
                color: white;
                text-align: center;
              }
            }

            .tab_total span {
              background: var(--devisTotalBg);
              color: var(--txt);
            }

            .tab_total {
              border: none;
            }

            .tab_comment {
              padding: 20px;
              width: 100%;

              textarea {
                width: 100%;
                height: 100px;
              }
            }

            button:focus {
              border: none;
              outline: none;
            }

            button.ajout_access {
              display: block;
              height: 36px;
              background: var(--devisBg);
              @include reset_margin;
              @include sassRem(normal, 0.75rem, var(--typo_light), 120%);
              color: white;
              text-align: center;
              text-transform: uppercase;
              // &::before, &::after {content: ''; flex: 1 0 auto;}

              em {
                margin: 0;
                margin-left: 10px;
                color: var(--devisTxt);
                font-family: var(--typo_light);
                font-size: 16px;
                align-items: center;
              }

              span {
                all: initial;
                font-size: 32px;
                font-family: var(--typo_bold);
                color: var(--devisTxt);
                line-height: 100%;
                margin-right: 10px;
              }

              &:hover,
              &:active {
                background: var(--devisBgAlt);

                em, span {
                  color: var(--devisTxtAlt);
                }
              }
            }
          }
        }

        .accessoireTAB {
          padding: 20px 0;
        }
      }

      .boutonsTAB {
        @include goFlex(row, wrap, flex-end, center);
        width: 100%;
        margin-bottom: 30px;

        button.btn_exporter,
        button.btn_generer {
          @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
          color: white;
          text-transform: uppercase;
          background: var(--saumonAlt);
          padding: 10px 20px;
          margin: 0 0 10px 20px;

          &:hover,
          &:active {
            background: var(--ocreAlt);
          }
        }
      }
    }

    .etude {
      padding: 10px 30px;

      .rechETUDE {
        @include goFlex(row, wrap, flex-end, center);
        width: 100%;

        form {
          @include reset_margin;
          width: 100%;

          .blocForm {
            @include goFlex(row, wrap, flex-end, center);

            input {
              @include reset_margin;
              height: 36px;
              padding-left: 10px;
              padding-right: 47px;
              width: 100%;
              min-width: auto;
            }

            i.data_search {
              display: inline-block;
              position: absolute;
              top: 6px;
              right: 8px;
              z-index: 10;
              background-color: var(--saumonAlt);
              mask: url(../img/picto-search.svg) no-repeat;
              width: 27px;
              height: 27px;
              margin-right: 10px;
              padding: 0px 0 0 10px;
              padding-top: 0 !important;
            }
          }
        }
      }

      .etudeTAB {
        @include goFlex(row, wrap, flex-start, stretch);
        width: 100%;
        margin-top: 40px;
        border-left: 1px solid var(--saumon);
        border-right: 1px solid var(--saumon);
        border-top: 5px solid var(--saumon);
        border-bottom: 5px solid var(--saumon);

        table.etudeDATAS {
          thead tr th {
            position: relative;
            padding: 10px 5px;
            border: 1px solid var(--saumon);
            @include sassRem(normal, 0.875rem, var(--typo_regular), 100%);
            font-weight: normal;

            &:last-child {
              display: none;
            }

            &.colorETUDE,
            &.colorRAPPORT {
              background: var(--saumon);
              color: white;
              border: 0;

              p {
                position: absolute;
                top: -35px;
                left: -1px;
                width: 100%;
                height: 30px;
                background: var(--saumon);
                border: 0 !important;

                em {
                  position: absolute;
                  top: 8px;
                  left: 8px;
                  @include sassRem(normal, 0.813rem, var(--typo_bold), 100%);
                  color: white;
                }
              }
            }

            &.colorRAPPORT,
            &.colorRAPPORT p {
              background: var(--ocre);
            }
          }

          tbody tr {
            border-bottom: 1px solid var(--saumon);

            &:nth-child(odd) {
              background: $gris;
            }

            td {
              position: relative;
              border: 1px solid var(--saumon);
              padding: 10px 5px;
              @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);

              &.nom {
                width: auto;
              }

              &.reference {
                width: 8%;
              }

              &.client {
                width: auto;
              }

              &.date {
                width: 8%;
              }

              &.statut {
                width: 8%;
              }

              &.email_etude {
                width: 70px;
              }

              &.email_rapport {
                width: 70px;
              }

              &.edit_etude {
                width: 70px;
              }

              &.edit_rapport {
                width: 70px;
              }

              &.delete {
                width: 30px;
              }

              &.version {
                display: none;
                padding: 0;
              }

              &.versionning {
                width: 30px;
                @include sassRem(normal, 1.5rem, var(--typo_regular), 100%);
              }

              &.email_etude,
              &.email_rapport,
              &.edit_etude,
              &.edit_rapport {
                text-align: center;

                button {
                  margin: 0 auto;
                }
              }
            }

            &.underTR {
              td {
                font-family: var(--typo_regular);
                font-size: 0.75rem;
                padding: 5px;
              }

              td.nom {
                padding-left: 20px;
              }

              td.versionning button {
                display: none;
              }

              td.version button {
                display: none;
              }
            }

            a.btn_email_etude span,
            a.btn_email_etude_2 span,
            a.btn_email_rapport span {
              position: relative;
              top: -2px;
              @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
              color: var(--saumon);
              text-align: left;
              cursor: pointer;
              display: none;
            }

            a.btn_email_rapport span {
              color: var(--ocre);
            }

            button.btn_delete,
            button.btn_version {
              position: relative;
              @include goFlex(row, wrap, flex-end, center);
              width: 100%;
              margin: 0 auto;
              transition: none;

              &:hover i,
              &:active i {
                background: url(../img/picto-delete-on.svg) no-repeat;
                transition: none;
              }

              i {
                display: inline-block;
                background: url(../img/picto-delete.svg) no-repeat;
                width: 17px;
                height: 21px;
                margin-right: 6px;
                transition: none;
              }

              span {
                display: none;
                @include reset_margin;
                @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
                color: white;
                text-align: center;
                padding: 4px 0;
                background: var(--saumon);
                transition: 0.3s;
                width: 140px;

                &:hover,
                &:active {
                  background: var(--ocre);
                  transition: 0.3s;
                }
              }
            }

            button.btn_versionning {
              @include sassRem(normal, 1.5rem, var(--typo_bold), 100%);
              color: var(--ocreAlt);
              width: 100%;
              margin: 0 auto;

              &:hover,
              &:active {
                color: var(--saumonAlt);
                transition: none;
              }
            }

            button,
            a {
              display: inline-block;
              transition: none;

              &.btn_email_etude {
                background-color: var(--saumon);
                mask: url(../img/picto-email-etude.svg) no-repeat;
                width: 28px;
                height: 17px;

                &:hover,
                &:active {
                  background: var(--ocre);
                  mask: url(../img/picto-email-rapport.svg) no-repeat;
                }
              }

              &.btn_email_rapport {
                background-color: var(--ocre);
                mask: url(../img/picto-email-rapport.svg) no-repeat;
                width: 28px;
                height: 17px;

                &:hover,
                &:active {
                  background: var(--saumon);
                  mask: url(../img/picto-email-etude.svg) no-repeat;
                }
              }

              &.btn_edit_etude {
                background-color: var(--saumon);
                mask: url(../img/picto-edit-etude.svg) no-repeat;
                width: 16px;
                height: 16px;

                &:hover,
                &:active {
                  background: var(--ocre);
                  mask: url(../img/picto-edit-rapport.svg) no-repeat;
                }
              }

              &.btn_edit_rapport {
                background-color: var(--ocre);
                mask: url(../img/picto-edit-rapport.svg) no-repeat;
                width: 16px;
                height: 16px;

                &:hover,
                &:active {
                  background: var(--saumon);
                  mask: url(../img/picto-edit-etude.svg) no-repeat;
                }
              }
            }
          }
        }
      }
    }

    .syntheseDatas {
      @include goFlex(row, wrap, flex-start, flex-start);
      margin: 0 !important;
      padding: 30px;

      .syntheseINFOS {
        @include goFlex(row, wrap, flex-start, stretch);
        width: 100%;

        .adresse,
        .deperdit,
        .bilan {
          @include goFlex(column, wrap, flex-start, flex-start);
          padding: 0 20px;

          p.titrage {
            @include sassRem(normal, 1rem, var(--typo_light), 100%);
            text-transform: uppercase;
            margin-bottom: 10px;
            padding: 0;
            border: none;
            width: 100%;
          }

          p {
            @include sassRem(normal, 0.938rem, var(--typo_light), 100%);
            width: 100%;
            padding: 5px 0;
            border-bottom: 1px solid $gris;

            strong {
              margin-left: 4px;
              font-family: var(--typo_bold);
            }
          }
        }

        .adresse p.titrage {
          color: black;
          font-family: var(--typo_bold);
          font-size: 1.25rem;
          position: relative;
          top: -20px;
        }

        .deperdit p.titrage {
          color: var(--saumonAlt);
        }

        .bilan p.titrage {
          color: var(--froid);
        }
      }

      .syntheseTAB {
        @include goFlex(row, wrap, flex-start, stretch);
        width: 100%;
        margin: 40px 20px;
        border-left: 1px solid var(--saumon);
        border-right: 1px solid var(--saumon);
        border-top: 5px solid var(--saumon);
        border-bottom: 5px solid var(--saumon);

        table.syntTAB {
          thead tr th {
            position: relative;
            padding: 10px !important;
            border-right: 1px solid var(--saumon);
            border-bottom: 1px solid var(--saumon);
            @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
            font-weight: normal;

            &.colorSaumon {
              color: var(--saumonAlt);
            }

            &.colorBleu {
              color: var(--froid);
            }
          }

          tbody tr {
            border-bottom: 1px solid var(--saumon);

            &:nth-child(odd) {
              background: $gris;
            }

            td {
              position: relative;
              padding: 10px;
              border-right: 1px solid var(--saumon);
              border-bottom: 1px solid var(--saumon);
              min-height: 39px;
              @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);

              &.modele {
                width: auto;
              }

              &.code {
                width: 8%;
              }

              &.taux {
                width: 8%;

                &::before {
                  color: var(--saumon);
                }
              }

              &.conso1 {
                width: 8%;

                &::before {
                  color: var(--saumon);
                }
              }

              &.cop {
                width: 8%;

                &::before {
                  color: var(--saumon);
                }
              }

              &.couv {
                width: 8%;

                &::before {
                  color: $bleu;
                }
              }

              &.eer {
                width: 8%;

                &::before {
                  color: $bleu;
                }
              }

              &.conso {
                width: 8%;

                &::before {
                  color: $bleu;
                }
              }

              &.prix {
                width: 10%;
              }

              &.details {
                width: 100px;
              }

              &.select {
                width: 30px;
              }

              &.details,
              &.details_thermor {
                position: relative;

                img {
                  @include responsive;
                }

                button.btn_add {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  z-index: 1;
                  @include goFlex(row, wrap, center, center);
                  @include sassRem(normal, 2rem, var(--typo_bold), 100%);
                  color: white !important;
                  width: 50px;
                  height: 50px;
                  margin-top: -25px;
                  margin-left: -25px;
                  border-radius: 50px;
                  background: var(--saumonAlt4);

                  &::before,
                  &::after {
                    content: '';
                    flex: 1 0 auto;
                  }

                  &:hover,
                  &:active {
                    background: var(--ocreAlt3);
                  }

                }

                button.btn_add_thermor {
                  width: 25px;
                  height: 25px;
                  margin-top: -12px;
                  margin-left: -12px;
                  border-radius: 25px;
                  font-size: 17px;
                }

              }

              &.details_thermor {
                padding: 0;
              }

              &.select {
                button.btn_select {
                  margin: 0 auto;
                  border: 1px solid var(--saumon);
                  border-radius: 50px;
                  background: white;
                  font-size: 0;

                  i {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border: 2px solid white;
                    border-radius: 50px;
                    background: white;
                    margin-left: auto;
                  }

                  span {
                    display: none;
                    @include reset_margin;
                    padding: 4px 15px;
                    @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
                    color: white;
                    background: var(--saumon);
                    text-align: right;
                  }

                  // &:before, &:after {display: none;}
                  &:active i,
                  &.active i {
                    background: var(--saumonAlt);
                  }
                }
              }
            }



          }
        }
      }
    }

    .tabTAB {
      div {
        @include goFlex(row, wrap, flex-start, flex-start);
      }

      em {
        display: flex;
        flex-basis: 100%;
      }

      p.imageBIG {
        margin: 20px 20px 20px 0;
      }

      p.total {
        text-align: right;
      }

      table {
        flex: 1;
      }
    }

    .titreRubrique {
      position: relative;

      p {
        position: relative;
        @include goFlex(row, wrap, flex-start, center);
        @include sassRem(normal, 1rem, var(--typo_light), 100%);
        color: var(--txtRubrique);
        text-transform: uppercase;
        width: calc(100% - 22px);
        height: 90px;
        padding: 0 15px;
        background: white;
        border: 1px solid var(--saumonAlt4);
      }

      p.froid {
        border-color: var(--froid);
      }

      p.pacific {
        @include sassRem(normal, 1rem, var(--typo_regular), 100%);
        border-width: 2px;
        border-radius: 15px;
      }

      

      img.chevron {
        display: block;
        position: absolute;
        top: 0;
        right: -6px;
        height: 90px;
      }
    }

    .contentRubrique {
      p {
        color: var(--saumonAlt4);
      }
    }

    .choixSimulation {
      max-width: 100%;
      width: 100%;
      margin: 0 auto 20px 0;
      padding: 0;
      padding-right: 40px;
      @include goFlex(row, wrap, flex-end, center);

      p {
        @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
        color: var(--ocreAlt4);
        text-transform: uppercase;
      }

      p.pacific {
        text-transform: none;
      }

      form {
        @include goFlex(column, wrap, center, flex-start);
        margin-left: 30px;

        .pretty.p-switch {
          @include goFlex(row, wrap, flex-start, flex-end);
          margin-right: 0;
          text-align: left;

          input:checked~.state:before {
            background: white !important;
            border-color: var(--saumonAlt3) !important;
          }

          input:checked~.state label {
            @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
            color: var(--saumon) !important;
          }

          input:checked~.state label:after {
            background: var(--saumonAlt3) !important;
          }

          margin: 5px 0;
        }

        .state {
          &:before {
            top: 0 !important;
            left: -2px !important;
            margin-top: 2px;
            background: $gris;
          }

          label {
            margin-left: 5px;
            padding-top: 6px;
            @include sassRem(normal, 0.875rem, var(--typo_regular), 100%);
            color: silver;
            text-transform: uppercase;

            &:after {
              position: absolute;
              top: 0px;
              background: silver !important;
              margin-top: 2px;
              margin-left: -2px;
            }

            &:before {
              position: absolute;
              top: 0px;
              background: silver !important;
              margin-top: 2px;
              margin-left: -2px;
            }
          }
        }
      }
    }

    .choixSticker {
      @include goFlex(row, wrap, flex-start, center);

      margin-bottom: 2px;

      .stickerChaud,
      .stickerFroid {
        @include goFlex(row, wrap, flex-start, center);
        padding: 10px 40px;
        background: $chaud;
        transition: 0.3s;

        &:hover,
        &:active {
          background: darken($chaud, 15%);
          transition: 0.3s;
        }

        em {
          @include sassRem(normal, 1.25rem, var(--typo_light), 160%);
          color: white;
          text-transform: uppercase;
        }

        img {
          display: inline-block;
          margin-right: 10px;
        }
      }

      .stickerChaud {
        margin-right: 2px;
      }

      .stickerFroid {
        left: 200px;
        background: $froid;

        img {
          margin-right: 5px;
        }

        &:hover,
        &:active {
          background: darken($froid, 15%);
        }
      }

      .stickerFroidThermor {
        background: $froid_thermor;

        &:hover,
        &:active {
          background: darken($froid_thermor, 15%);
        }
      }

      .stickerFroidPacific {
        background: $froid_pacific;

        &:hover,
        &:active {
          background: darken($froid_pacific, 15%);
        }
      }

      .stickerChaudPacific {
        background: $chaud_pacific;

        &:hover,
        &:active {
          background: darken($chaud_pacific, 15%);
        }
      }
    }

    .donneesGEO {
      position: relative;
      @include goFlex(row, wrap, flex-start, flex-start);
      margin-bottom: 30px;
      background: $gris;

      .titreRubrique p button {
        position: absolute;
        right: 5px;
        display: inline-block;
        background-color: var(--saumon);
        mask: url(../img/picto-info.png) no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        margin-top: 5px;

        &.thermorfr-cold {
          background-color: var(--froid);
          mask: url(../img/picto-info.png) no-repeat;
        }
      }

      .form {
        @include reset_margin;

        .orientation {
          position: absolute;
          top: 55px;
          right: 5px;
        }

        .row {
          @include goFlex(row, wrap, flex-start, center);
          padding: 27px 30px;
        }

        .row.row_cool_config {
          padding: 5px 30px;

          &:first-child {
            padding-top: 27px;
          }

          &:last-child {
            padding-bottom: 27px;
          }
        }

        .row .cool_config_header {
          font-weight: bold;
          color: var(--froid);
        }

        .blocForm {
          margin-bottom: 0;
          margin-right: 30px;
          width: calc(100% - 30px);
        }

        .pretty {
          margin: 10px 30px 10px 0;

          input[type='checkbox']:checked+.state {
            label:before {
              border-color: var(--saumon) !important;
            }
            label:after {
              background: var(--saumon) !important;
            }
          }
        }

        select,
        input {
          @include reset_margin;
          height: 36px;
          margin-right: 30px;
          padding-left: 10px;
          border-color: var(--saumon);
          width: 100%;
        }

        input[name="altitude"] {
          padding-left: 48px;
        }

        label[name="altitude"] {
          padding-left: 44px;
        }

        select#data_departement,
        select#data_departement_thermor {
          padding-left: 32px;

          &+label {
            padding-left: 31px;
          }
        }

        select#data_meteo {
          padding-left: 46px;

          &+label {
            padding-left: 45px;
          }
        }

        input#data_altitude {
          width: 100%;
          padding-left: 46px;
          margin-right: 0;

          &+label {
            padding-left: 42px;
          }
        }

        .blocForm {
          margin-bottom: 0;
          margin-right: 30px;
        }

        .blocForm {
          position: relative;

          i.data_departement {
            display: block;
            position: absolute;
            top: 6px;
            left: 8px;
            z-index: 5;
            // background-color: var(--saumon);
            // mask: url(../img/picto-departement.svg) no-repeat;
            background: url(../img/picto-departement-old.svg);
            width: 18px;
            height: 25px;
          }

          i.data_departement_thermor {
            display: block;
            position: absolute;
            top: 6px;
            left: 8px;
            z-index: 5;
            // background-color: var(--saumon);
            // mask: url(../img/picto-departement.svg) no-repeat;
            background: url(../img/picto-departement.svg);
            width: 18px;
            height: 25px;
          }

          i.data_meteo {
            display: block;
            position: absolute;
            top: 3px;
            left: 8px;
            z-index: 5;
            background-color: var(--saumon);
            mask: url(../img/picto-meteo.svg) no-repeat;
            width: 34px;
            height: 30px;
          }

          i.data_altitude {
            display: block;
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 5;
            background-color: var(--saumon);
            mask: url(../img/picto-altitude.svg) no-repeat;
            width: 33px;
            height: 22px;
          }
        }
      }
    }

    .donneesHAB {
      position: relative;
      @include goFlex(row, wrap, flex-start, flex-start);
      margin-bottom: 30px;
      background: $gris;

      .row {
        @include goFlex(row, wrap, flex-start, center);
        padding: 0 30px;

        .maison_mitoyennete,
        .maison_type {
          @include goFlex(row, wrap, flex-start, center);
          @include sassRem(normal, 1rem, var(--typo_bold), 120%);
          color: var(--saumon);
          text-align: left;
          text-transform: uppercase;

          button {
            @include goFlex(column, wrap, center, center);
            @include reset_margin;
            @include sassRem(normal, 0.75rem, var(--typo_regular), 120%);
            color: var(--saumon);
            text-align: center;
            margin: 0 5px;
            padding: 10px;
            height: 100%;

            span {
              display: block;
              background: transparent;
              width: 0;
              height: 0;
              margin-bottom: 4px;
            }

            &.maison_seule span {
              background-color: var(--saumon);
              mask: url(../img/picto-maison-seule.svg) no-repeat;
              width: 51px;
              height: 56px;
            }

            &.maison_seule:hover span,
            &.maison_seule:active span,
            &.maison_seule.active span {
              background: url(../img/picto-maison-seule-on.svg) no-repeat;
            }

            &.maison_1mur span {
              background-color: var(--saumon);
              mask: url(../img/picto-maison-1mur.svg) no-repeat;
              width: 62px;
              height: 56px;
            }

            &.maison_1mur:hover span,
            &.maison_1mur:active span,
            &.maison_1mur.active span {
              background: url(../img/picto-maison-1mur-on.svg) no-repeat;
            }

            &.maison_2murs span {
              background-color: var(--saumon);
              mask: url(../img/picto-maison-2murs.svg) no-repeat;
              width: 92px;
              height: 56px;
            }

            &.maison_2murs:hover span,
            &.maison_2murs:active span,
            &.maison_2murs.active span {
              background: url(../img/picto-maison-2murs-on.svg) no-repeat;
            }

            &.maison_rdc span {
              background-color: var(--saumon);
              mask: url(../img/picto-maison-rdc.svg) no-repeat;
              width: 51px;
              height: 56px;
            }

            &.maison_rdc:hover span,
            &.maison_rdc:active span,
            &.maison_rdc.active span {
              background: url(../img/picto-maison-rdc-on.svg) no-repeat;
            }

            &.maison_r1 span {
              background-color: var(--saumon);
              mask: url(../img/picto-maison-r1.svg) no-repeat;
              width: 41px;
              height: 55px;
            }

            &.maison_r1:hover span,
            &.maison_r1:active span,
            &.maison_r1.active span {
              background: url(../img/picto-maison-r1-on.svg) no-repeat;
            }

            &:hover,
            &:active,
            &.active {
              background: var(--saumonAlt);
              color: white;

              &.pacific {
                // background: linear-gradient(135deg, #E52E39, #E52E39, #E52E39, #008AAC, #008AAC);
                background: radial-gradient(circle at bottom right, #008AAC, #008AAC, #008AAC, #E52E39, #E52E39, #E52E39);
              }
            }
          }
        }

        .maison_type {
          margin-left: 50px;
        }
      }
    }

    .donneesDESC {
      position: relative;
      @include goFlex(row, wrap, flex-start, flex-start);
      margin-bottom: 30px;
      background: $gris;

      .titreRubrique p button {
        position: absolute;
        right: 5px;
        display: inline-block;
        background-color: var(--saumon);
        mask: url(../img/picto-info.png) no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        margin-top: 5px;

        &.thermorfr-cold {
          background-color: var(--froid);
          mask: url(../img/picto-info.png) no-repeat;
        }
      }

      .form {
        @include reset_margin;

        .row {
          @include goFlex(row, wrap, flex-start, center);
          padding: 27px 15px 27px 30px;
        }

        .row .row {
          padding: 0;
        }

        .row:nth-child(2) {
          padding-top: 0;
        }

        .zone {
          @include goFlex(row, wrap, flex-start, center);

          p {
            @include sassRem(normal, 1rem, var(--typo_bold), 120%);
            color: var(--saumonAlt4);
            text-align: left;
            text-transform: uppercase;
          }
        }

        .blocForm {
          margin-bottom: 0;
          margin-right: 30px;
          width: calc(100% - 30px);
        }

        input,
        select {
          @include reset_margin;
          height: 36px;
          border-color: var(--saumon);
          width: 100%;
        }

        select.data_emet {
          display: block;
          padding-left: 10px;
          width: 100%;
        }

        select.data_temp {
          display: block;
          padding-left: 10px;
          width: 100%;
        }

        input {
          padding-left: 10px !important;
        }

        button.ajout_zone {
          @include goFlex(row, wrap, center, center);

          // Bug alignement sur Safari
          &::before,
          &::after {
            content: '';
            flex: 1 0 auto;
          }

          @include reset_margin_center;
          background: var(--saumonAlt);
          width: 100%;
          height: 36px;

          i {
            margin-right: 5px;
            @include sassRem(normal, 1rem, var(--typo_bold), 120%);
            color: white;
          }

          em {
            width: 52px;
            @include sassRem(normal, 0.625rem, var(--typo_bold), 100%);
            color: white;
            text-align: left;
            text-transform: uppercase;
          }

          &:hover,
          &:active {
            background: var(--ocreAlt);
          }
        }
      }
    }

    .donneesDEP {
      position: relative;
      @include goFlex(row, wrap, flex-start, flex-start);
      margin-bottom: 30px;
      background: $gris;

      .titreRubrique p button {
        position: absolute;
        right: 5px;
        display: inline-block;
        background-color: var(--saumonAlt);
        mask: url(../img/picto-info.png) no-repeat;
        mask-size: cover;
        background-size: 20px 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        //margin-top: 3px;
        

        &.thermorfr-cold {
          background-color: var(--froid);
          mask: url(../img/picto-info.svg) no-repeat;
        }
      }

      .contentRubrique {
        padding: 27px 30px;

        .deperdition {
          .form {
            @include reset_margin;
            width: 100%;

            .row {
              @include goFlex(row, wrap, flex-start, center);
            }

            .blocForm {
              position: relative;

              input,
              select {
                width: 100%;
                @include reset_margin;
                height: 36px;
                margin-right: 30px;
                border-color: var(--saumon);
              }

              input {
                padding-left: 10px;
              }

              select#data_methode {
                padding-left: 10px;
              }

              input#data_temperature {
                padding-left: 32px;
                margin-right: 0;
              }

              i.data_temperature {
                display: block;
                position: absolute;
                top: 5px;
                left: 8px;
                z-index: 5;
                background: url(../img/picto-temperature.svg) no-repeat;
                width: 16px;
                height: 27px;
              }
            }
          }
        }

        .estimation {
          @include goFlex(row, wrap, flex-start, strech);
          padding-top: 10px;

          .form {
            @include reset_margin;

            input,
            select {
              @include reset_margin;
              height: 36px;
              margin-right: 30px;
              border-color: var(--saumon);
            }

            input,
            select {
              padding-left: 10px;
              width: 100%;
              margin-bottom: 10px;
            }

            input:disabled,
            select:disabled {
              opacity: 0.7;
            }

            input {
              padding-left: 10px;
              width: 100%;
            }

            input#data_temperature {
              padding-left: 32px;
            }

            i.data_temperature {
              display: block;
              position: absolute;
              top: 5px;
              left: 8px;
              z-index: 5;
              background: url(../img/picto-temperature.svg) no-repeat;
              width: 16px;
              height: 27px;
            }

            .choixTravaux {
              @include goFlex(column, wrap, flex-start, flex-start);
              margin: 10px 0;

              p {
                width: 100%;
                @include sassRem(normal, 0.875rem, var(--typo_light), 120%);
                text-align: left;
              }

              .choixIsolation {
                @include goFlex(row, wrap, flex-start, flex-start);
                width: 100%;

                .pretty {
                  margin: 10px 30px 10px 0;

                  input[type='radio']:checked+.state label:after {
                    background: var(--saumon) !important;
                  }
                }

                .state {
                  &:before {
                    top: 0 !important;
                    left: -2px !important;
                    margin-top: 2px;
                    background: $gris;
                  }

                  label {
                    position: relative;
                    @include sassRem(normal, 0.875rem, var(--typo_light), 100%);

                    &:before {
                      margin-top: -1px;
                      border-color: var(--saumon);
                    }

                    &:after {
                      margin-top: -1px;
                    }
                  }
                }
              }
            }
          }

          .besoin {
            @include goFlex(column, wrap, flex-start, flex-start);
            padding: 0px 0;

            p.titreBesoin {
              width: 100%;
              @include sassRem(normal, 2rem, var(--typo_light), 100%);
              text-align: center;
              color: var(--saumonAlt4);
            }

            .row {
              margin-top: 30px;

              .infosBesoin,
              .infosBesoin1 {
                padding: 0 70px;

                p {
                  min-height: 45px;
                  @include sassRem(normal, 1rem, var(--typo_bold), 130%);
                  color: var(--saumonAlt4);
                  text-align: left;
                }

                em {
                  display: block;
                  margin: 10px 0;
                  @include sassRem(normal, 2rem, var(--typo_bold), 100%);
                  color: var(--saumonAlt4);
                  text-align: left;
                }

                &.col-12 {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                }
              }

              .infosBesoin1 {
                border-right: 2px solid var(--saumonAlt4);
              }
            }
          }
        }
      }
    }

    .donneesACC {
      position: relative;
      @include goFlex(row, wrap, flex-start, flex-start);
      margin-bottom: 30px;
      background: $gris;

      .contentRubrique {
        padding: 27px 30px;

        nav.navProduits {
          width: 100%;

            .navProduitsGroup {
              display: inline-block;
              margin: 5px 0;
              width: 100%;

              span {
                @include sassRem(normal, 0.813rem, var(--typo_bold), 100%);
                width: 100%;
                padding: 10px 0px;

                text-decoration: none;
                color: var(--saumonAlt);
                background: none;
                font-size: 1.4em;
              }

              &.pacific {
                span {
                  @include sassRem(normal, 0.813rem, var(--typo_regular), 100%);
                  text-decoration: none;
                  width: 100%;
                  padding: 10px 15px;

                  background: none;
                  @include sassRem(normal, 0.813rem, var(--typo_bold), 100%);
                  color: black;
                  font-size: 1.4em;
                }
              }
            }
          


        }

        .allProduits {
          padding: 15px 0;
          align-items: start;

          .blocProduit {
            @include goFlex(column, wrap, center, flex-start);
            width: 100%;
            margin-bottom: 30px;
            padding-right: 30px;
            opacity: 0.5;
            transition: 0.3s;

            &:hover,
            &:active,
            &.active {
              opacity: 1;
              transition: 0.3s;
              cursor: pointer;
            }

            &:active,
            &.active {
              button.btn_select i {
                background: var(--saumonAlt);
              }
            }

            .btn_select {
              @include goFlex(row, wrap, flex-start, center);
              padding: 8px;
              width: 100%;
              background: $gris_fonce;
              border-radius: 0 !important;

              em {
                @include sassRem(normal, 0.75rem, var(--typo_light), 100%);
                color: white;
                text-align: left;
              }

              i {
                display: inline-block;
                width: 20px;
                height: 20px;
                border: 2px solid white;
                border-radius: 50px;
                background: white;
                margin-left: 3px;
              }

              // &:before, &:after {display: none;}
            }

            .imageProduit {
              display: block;
              width: 100%;
              background: white;
              border: 1px solid var(--saumon);

              img {
                @include responsive;
              }
            }

            .texteProduit {
              padding: 20px 10px;
              background: var(--saumonAlt5);
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;

              &.pacific {
                background: none;
                border: 1px solid var(--saumon);
                border-top: none;
              }

              p {
                width: 100%;
                @include sassRem(normal, 0.875rem, var(--typo_light), 130%);
                color: white;
                text-align: center;
                word-break: break-word;

                &.pacific {
                  color: var(--saumon);
                }
              }
            }
          }
        }
      }
    }

    .barreProduit {
      @include goFlex(row, wrap, flex-start, flex-start);
      margin: 50px;

      .produitLibelle,
      .produitCode,
      .produitPrix {
        @include goFlex(row, wrap, center, center);
        background: var(--pdtLib);
        height: 40px;

        p {
          padding: 0 10px;
          @include sassRem(normal, 1.125rem, var(--typo_bold), 130%);
          color: white;
          text-align: center;
        }

        button.btn_close {
          display: block;
          @include reset_margin;
          @include sassRem(normal, 1.25rem, var(--typo_bold), 100%);
          color: white;
          margin-left: auto;
          padding: 0 12px;
          height: 40px;
          background: var(--ocreAlt2);

          &:hover,
          &:active {
            background: var(--saumonAlt4);
          }
        }
      }

      .produitCode {
        background: var(--pdtCode);
      }

      .produitCodePacific {
        background: linear-gradient(90deg, var(--pdtLib), var(--pdtPrix));
      }

      .produitPrix {
        background: var(--pdtPrix);

        p {
          margin-left: auto;
          color: var(--ocreAlt5);
        }
      }
    }

    .contentProduit {
      @include goFlex(row, wrap, flex-start, flex-start);

      margin: 0 70px 0 70px;

      .sidebar {
        @include goFlex(column, wrap, flex-start, flex-start);
        padding-bottom: 20px;
        width: 100%;

        nav.produitMenu {
          margin-bottom: 20px;

          ul {
            @include resetUL;
            @include goFlex(column, wrap, flex-start, flex-start);
            font-size: 0;

            li {
              margin: 5px 0;

              span {
                width: 100%;
                @include goFlex(row, wrap, center, center);
                @include sassRem(normal, 0.938rem, var(--typo_light), 100%);
                color: black;
                text-decoration: none;
                border-bottom: 5px solid transparent;
                transition: 0.3s;
              }

              &:hover span,
              &:active span,
              &.active span {
                border-bottom: 5px solid var(--saumonAlt);
                text-decoration: none;
                transition: 0.3s;
              }
            }
          }
        }

        .imgProduit img {
          @include responsive;
        }
      }

      .leContenu {
        @include goFlex(column, wrap, flex-start, flex-start);
        flex: 1;
        padding: 0;

        div.contenu {
          width: 100%;
          margin-top: 10px;
        }

        strong {
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        p {
          width: 100%;
          margin-bottom: 10px;
          @include sassRem(normal, 1rem, var(--typo_light), 100%);
          color: black;

          &.titre_produit {
            @include sassRem(normal, 1.375rem, var(--typo_bold), 100%);
            color: var(--saumonAlt4);
          }

          i {
            display: inline-block;
            background: url(../img/puce.svg) no-repeat;
            width: 12px;
            height: 12px;
            margin-right: 2px;
          }
        }

        .note {
          font-size: 0.825em
        }
      }
    }

    .synthese {
      @include goFlex(row, wrap, flex-start, flex-start);
      margin: 50px;

      .choixDOC {
        margin-bottom: 30px;
        padding-right: 50px;
        border-right: 1px solid var(--saumon);

        em {
          display: block;
          margin: 30px 0 5px 0;
          @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
        }

        p {
          @include goFlex(row, wrap, flex-start, center);
          @include sassRem(normal, 1rem, var(--typo_bold), 100%);
          text-transform: uppercase;
          width: 100%;

          i.fichierPDF {
            display: inline-block;
            background: url(../img/picto-fichier-pdf.png) no-repeat;
            width: 37px;
            height: 37px;
            margin-right: 6px;
          }
        }

        div.form {
          @include goFlex(column, wrap, flex-start, flex-start);
          padding-left: 15px;

          button {
            @include goFlex(row, wrap, center, center);
            @include sassRem(normal, 1.25rem, var(--typo_bold), 100%);
            text-transform: uppercase;
            color: white;
            width: 100%;
            background: linear-gradient(135deg, var(--ocre), var(--ocre), var(--ocre), var(--ocreAlt2), var(--ocreAlt2));
            margin: 30px 0;
            padding: 8px;
            opacity: 1;

            i {
              display: inline-block;
              background: url(../img/picto-generer.svg) no-repeat;
              width: 31px;
              height: 31px;
              margin-right: 10px;
            }

            &:hover,
            &:active {
              background: linear-gradient(135deg, var(--saumonAlt4), var(--saumonAlt4), var(--saumonAlt4), var(--saumonAlt2), var(--saumonAlt2));
            
              &.pacific {
                transition: opacity 0.5s;
                opacity: 0.85;
              }
            }
          }

          .pretty {
            margin-top: 10px;

            input[type='checkbox']:checked+.state label:after {
              background: var(--saumonAlt4) !important;
            }
          }

          .state {
            &:before {
              top: 0 !important;
              left: -2px !important;
              margin-top: 2px;
              background: $gris;
            }

            label {
              position: relative;
              padding: 0 0 0 4px;
              @include sassRem(normal, 0.875rem, var(--typo_regular), 100%);

              &:before {
                border-color: var(--saumon);
              }
            }
          }
        }
      }

      .downloadDOC {
        width: 100%;
        padding-left: 50px;

        &>p {
          @include goFlex(row, wrap, flex-start, center);
          @include sassRem(normal, 1rem, var(--typo_bold), 100%);
          text-transform: uppercase;
          width: 100%;
          padding: 11px 0;
        }

        div {

          a,
          button {
            @include goFlex(row, wrap, flex-start, center);
            padding: 10px 0;
            border-bottom: 1px solid silver;
            width: 100%;
            color: black;

            // &:before, &:after {display: none;}

            em {
              display: flex;
              flex: 1 0 auto;
              @include sassRem(normal, 0.875rem, var(--typo_light), 100%);
            }

            i.fichierPDF {
              background: url(../img/picto-fichier-pdf.png) no-repeat;
              width: 37px;
              height: 37px;
              margin-right: 4px;
            }

            i.fichierDOC {
              background: url(../img/picto-fichier-doc.svg) no-repeat;
              width: 41px;
              height: 32px;
              margin-right: 6px;
            }

            i.download {
              background-color: var(--saumonAlt);
              mask: url(../img/picto-download.svg) no-repeat;
              width: 31px;
              height: 31px;
              margin-left: auto;
              margin-right: 4px;
            }

            &:hover,
            &:active {
              background: #{rgba(var(--saumon), 0.75)};

              i.download {
                background-color: var(--ocreAlt);
                mask: url(../img/picto-download-on.svg) no-repeat;
              }
            }
          }
        }
      }
    }
  }

  // Footer
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    background: var(--saumonAlt5);
    width: 100%;
    margin-top: 30px;
    z-index: 10;

    .row {
      @include goFlex(row, wrap, center, center);
      padding: 0 10px;
      max-width: 1200px;
      margin: auto;

      .bottomBarre {
        @include goFlex(row, wrap, space-between, center);
        height: 44px;
      }

      button {
        @include reset_margin;
        @include goFlex(row, wrap, center, center);

        &.enregistrer {
          @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
          color: var(--txtBottom);
          text-transform: uppercase;
          letter-spacing: 1px;
          padding: 15px 40px;
          background: var(--saumonAlt5);
          border-radius: 0;

          &:hover,
          &:active {
            span {
              background: url(../img/fleche-gauche-on.svg) no-repeat;
            }

            background: var(--ocre);

            &.pacific {
            // background: linear-gradient(90deg, #f2f2f2, #E52E39, #008AAC, #f2f2f2);
            background: linear-gradient(135deg, #E52E39, #E52E39, #E52E39, #008AAC, #008AAC, #008AAC);
            color: white;
            text-decoration: none;
            letter-spacing: 2px;
            }
          }
        }

        &.navPrev {
          @include sassRem(normal, 0.875rem, var(--typo_bold), 120%);
          color: var(--txtBottom);
          text-transform: uppercase;
          text-align: left;

          i {
            position: relative;
            top: -1px;
            display: inline-block;
            background-color: var(--txtBottom);
            mask: url(../img/fleche-gauche.svg) no-repeat;
            width: 22px;
            height: 15px;
            margin-right: 8px;
            transition: 0.3s;
          }

          &:hover,
          &:active {
            color: var(--ocre);
            text-decoration: none;

            i {
              background-color: var(--ocre);
              mask: url(../img/fleche-gauche.svg) no-repeat;
              transition: 0.3s;
            }
          }
        }

        &.navNext {
          @include sassRem(normal, 0.875rem, var(--typo_bold), 120%);
          color: var(--txtBottom);
          text-transform: uppercase;
          text-align: right;

          i {
            position: relative;
            top: -1px;
            display: inline-block;
            background-color: var(--txtBottom);
            mask: url(../img/fleche-droite.svg) no-repeat;
            width: 22px;
            height: 15px;
            margin-left: 8px;
            transition: 0.3s;
          }

          &:hover,
          &:active {
            color: var(--ocre);
            text-decoration: none;

            i {
              background-color: var(--ocre);
              mask: url(../img/fleche-droite.svg) no-repeat;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }

  // Pagination
  .paginationContr {
    width: 100%;
    padding: 8px;
    font-weight: 500;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination {
    padding: 8px;
    display: flex;

    &.btn {
      border: 0.25px solid #f47e60;
      padding: 7px 14px;
      cursor: pointer;

      &.active1 {
        color: white;
        pointer-events: none;
        
        &.pacific {
          background: $chaud_pacific;
        }

        &.atlantic {
          background: $chaud;
        }

        &.thermorfr {
          background: $chaud_pacific;
        }

      }

      &.inactive {
        color: #2c3e50;

        &.disabled {
          pointer-events: none;
        }
      }
 
      &.inactive:hover {
       background: #eba29065;
      }
    }
  }

  .pagination li {
    list-style-type: none;
  }
  //info
  .info {
    position: relative;
    margin-left: 15%;
    color: white;
    @include goFlex(column, wrap, center, center);
    @include reset_margin_center;
    background: var(--choixHome);
    width: 100%;
    max-width: 500px;

    .infoTitle {
      @include goFlex(row, wrap, center, center);
      //display: inline-block;
      font-size: 36px;
      margin-left: 20px;
      margin-top: 40px;

        i {
          display: inline-block;
          background-color: white;
          mask: url(../img/picto-menu-aide.svg) no-repeat;
          width: 35px;
          height: 35px;
          margin-right: 8px;
        }
    }

    .infoBody {
      @include goFlex(row, wrap, center, center);
      min-height: 300px;
      margin: 20px;
    }
  }
}

// Mediaqueries
@import 'mediaquery';