.myAccount {
  :global(.logo) {
    display: flex;
    justify-content: center;
    :global(.logo-img) {
      display: block;
      width: 200px;
      margin: auto;
    }
  }
  .loaderCenterer {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  .loader {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #F47D5F;
    border-color: #F47D5F transparent #F47D5F transparent;
    animation: loader 1.2s linear infinite;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}