
.satisfactionModal {
  width: 100%;
  .close {
    font-size: 25px;
    font-weight: 800;
    position: absolute;
      right: 10px;
      top: -3px;
      cursor: pointer;
      color: grey;
  }

  h3 {
    font-weight: 900;
    position: relative;
      top: 40px;
  }

  .logo {
    margin-left: 10px;
    p {
      font-size: 22px;
      margin-right: 5px;
      position: relative;
        top: 45px;
    }
    svg {
      position: relative;
        top: 5px;
    }
  }

  h4 {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 800;
  }

  .text {
    margin-top: 15px;
  }

  .actionBlock {
    margin-top: 25px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 20px;
    display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
}

.row {
  display: flex;
  flex-direction: row;
}