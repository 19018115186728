.HelpSideModal {
  .wrapper {
    width: 0;
  }
  .content {
    background: linear-gradient(45deg, var(--saumon0) 0%, var(--saumon6) 100%);
    z-index: 1000;
    width: 600px;
    height: 100vh;
    overflow: wrap;
    overflow-y: scroll;
    position: fixed;
    top: 0px;
    right: -600px;
    padding-bottom: 44px;
    animation: slide-in 0.5s forwards;
    .contentHeader {
      padding: 15px;
      float: right;
      .closeButton {
        font-size: 24px;
        color: var(--ocreAlt);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .contentBody {
      margin-top: 40px;
      font-size: 14px;
      padding: 30px;
        padding-left: 60px;
      color: var(--txt);
      h1, h2, h3, h4, h5, h6 {
        font-weight: 800;
        position: relative;
          left: -30px;
      }
    }
    table {
      width: 50%;
      td {
        border: 1px solid var(--txt);
        padding: 2px 10px;
      }
    }
  }
}

.HelpSideModalOpen {
  .wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 999;
  }
  .content {
    animation: slide-out 0.5s forwards;
  }
}

.HelpSideModalNeverOpen {
  .content {
    width: 0px;
  }
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
  0% { transform: translateX(-100%); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}