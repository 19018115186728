
.rateSatisfaction {
  margin-top: 30px;
}

.rateLine {
  width: 100%;
  display: flex !important;
  margin-top: 5px;
  padding-bottom: 5px;
    flex-direction: row;
    justify-content: space-between;
  border-bottom: solid lightgrey 1px;
  .left {
    min-height: 40px;
    width:100%;
    display: table;
    .leftContent {
      vertical-align: middle;
      min-height: 40px;
      display: table-cell;
      .description {
        font-size: 13px;
      }
    }
  }

  .right {
    position: relative;
      left: -45px;
    width: 175px;
    min-height: 40px;
    .rightContent {
      position: absolute;
        bottom: 0;
    }
  }
}

@media (max-width:600px) {
  .rateLine .right {
    left: 0;
  }
}