// Mediaqueries
@mixin taille($size) {
  @if $size == mobile440 {
    @media (max-width: 439px) {
      @content;
    }
  // Extra small devices (portrait phones, less than 576px)
  } @else if $size == mobile576 {
    @media (max-width: 575.98px) {
      @content;
    }

  // Small devices (landscape phones, 576px and up)
  } @else if $size == tablette768 {
    @media (max-width: 767.98px) {
      @content;
    }
  // Medium devices (tablets, 768px and up)
  } @else if $size == tablette992 {
    @media (max-width: 991.98px) {
      @content;
    }
  // Large devices (desktops, 992px and up)
  } @else if $size == desktop1200 {
    @media (max-width: 1199.98px) {
      @content;
    }
  // Extra large devices (large desktops, 1200px and up)
  } @else if $size == desktopUP {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

// Styles des mediaqueries
body {
  // Desktop + 1200px
  @include taille(desktopUP) {
  }

  // Desktop < 1200px
  @include taille(desktop1200) {
    #root .header .menuBarre {
      width: 100%;
      min-width: 100%;
      padding: 0 20px;
    }
    #root .header .bandeauThermor {
      min-width: 100%;
    }
  }

  // Tablette < 992px
  @include taille(tablette992) {

    background: white;
    //height: auto;

    #root .main .contentProduit {
      margin: 0 50px 0 50px;

      .sidebar {
        @include goFlex(row, wrap, flex-start, flex-start);
        padding: 0 20px;

        nav.produitMenu {order: 1; padding-left: 20px;}
        nav.imgProduit {order: 0;}
      }
    }

    #root {
      background: white;
      //height: auto;
    }

    #root .versionApp {
      margin-left: 40px;
    }

    #root .main.container-fluid.myAccountInfos {
      margin-left: 15px;
      margin-top: 20px;
    }

    #root .pageHome, #root .pageChoix {background: url(../img/background_1024.jpg) no-repeat; background-position: center; background-size: cover;}
    #root .pageHomeThermor, #root .pageChoix {background: url(../img/background_thermor_1024.jpg) no-repeat; background-position: center; background-size: cover;}
    #root .pageHomePacific, #root .pageChoix {background-image: none}

    #root .pageAide .main .row.blocAide {width: 90%; padding: 20px 0;}
    #root .pageAide .main .row.blocAide .contentAide .blocVideos .laVideo iframe {margin-bottom: 20px; padding-right: 0;}

    #root .header {
      .row {@include goFlex(row, wrap, flex-end, center);}
      .topBarre {
        padding: 5px 10px;

        .topMenu {display: none;}
        button.btn-menuMobile {display: block;}
        .thermorReduceMode { display: inline;}
      }

      .menuBarre {padding: 0 10px;}
      .menuBarre .menuProjipac {display: none;}
      .menuBarre .logoProjipac {
        @include goFlex(row, wrap, flex-start, center); flex: 1;
        margin: 0;
        p {display: inline-block !important;}
        .logoProjipacImg {width: 40px;}
        .logoThermorImg {width: 54px; height: 39px; margin-left: -10px;}
        .logoPacificImg {width: 40px; height: 40px; filter: none}    
      }

      .menuBarre .logoAtlantic {
        .logoPacific2Img {height: 16px; width: 144px;}
      }

      .bandeauThermor { display: none; }

      .bandeEtapes {display: flex;}
    }

    #root .main .titreRubrique {
      img, img.chevron {display: none;}
      p {width: 100%; height: 60px; border-left: none; border-right: none;}
      p br {
        content: ' '
      }
      p br:after {
        content: ' '
      }
      box-shadow: 0px 6px 0px rgba(black, 0.1);
    }

    #root .main .donneesGEO {margin-bottom: 0;}
    #root .main .donneesGEO .contentRubrique .form .row {
      padding: 20px !important;
      select#data_departement, select#data_meteo, select#data_altitude {width: 100%; margin-bottom: 10px;}
      select#data_altitude {margin-bottom: 0;}
    }

    #root .main .donneesHAB {margin-bottom: 0; padding-bottom: 20px;}
    #root .main .donneesHAB .contentRubrique .row {
      width: 100%; padding: 0;

      .maison_mitoyennete, .maison_type {
        border-bottom: 1px solid var(--saumon);
        margin: 0; padding-top: 10px;
        width: 50%;

        p {width: 100%; padding: 10px 20px 5px 20px; border-bottom: 1px solid var(--saumon);}
      }
    }

    #root .main .donneesDESC {margin-bottom: 0;}
    #root .main .donneesDESC .form .zone p {margin-bottom: 5px;}
    #root .main .donneesDESC .contentRubrique .form .row {
      margin: 0 !important; padding: 20px !important;
      input.data_surfaceZ1, input.data_hauteurZ1, select.data_emet, select.data_temp, button.ajout_zone {width: 100%; margin-bottom: 10px;}
      button.ajout_zone em {width: auto; line-height: 180%;}
      input#data_altitude {margin-bottom: 0;}
    }
    #root .main .donneesDESC .contentRubrique .form .row .col .row {padding: 0 !important;}

    #root .main .donneesDEP {margin-bottom: 0;}
    #root .main .donneesDEP .contentRubrique {padding: 0;}
    #root .main .donneesDEP .contentRubrique .form .row {
      margin: 0 !important; padding: 20px !important;
      select#data_methode, input#data_deperdition, select#data_temperature {width: 100%; margin-bottom: 10px;}
      select#data_temperature {margin-bottom: 0;}
    }
    #root .main .donneesDEP .contentRubrique .estimation {
      margin-top: -30px;
    }
    #root .main .donneesDEP .contentRubrique .estimation {
      padding: 20px 20px 10px 20px;
      input {width: 100%; margin-bottom: 10px;}
      .besoin {padding: 10px 0;}
      .besoin .row {@include goFlex(row, wrap, center, flex-start); width: 100%; margin-top: 20px;}
      .besoin .row .infosBesoin, .besoin .row .infosBesoin1 {
        padding: 0 0 0 20px;

        p {text-align: left;}
        em {font-size: 1.750rem; text-align: left;}

        &:first-child {
          text-align: right; padding: 0 20px 0 0;
          p, em {text-align: right;}
        }
      }

      select {margin-right: 0; width: 100%;}
      select {margin-bottom: 0}
    }

    #root .main .donneesACC .contentRubrique .allProduits {
      @include goFlex(row, wrap, flex-start, flex-start);

      .blocProduit {margin-bottom: 20px; padding-right: 20px;}
    }

    #root .main .donneesGEO .contentRubrique .form .row {
      select, input {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    #root .main .etude .etudeTAB {margin: 10px 0 20px 0;}
    #root .main .syntheseDatas {padding: 10px 20px;}
    #root .main .syntheseDatas .syntheseINFOS .adresse {padding-left: 0;}
    #root .main .syntheseDatas .syntheseINFOS .adresse p.titrage {top: 0;}
    #root .main .syntheseDatas .syntheseINFOS .bilan {margin-top: 25px; padding-left: 0;}
    #root .main .syntheseDatas .syntheseINFOS .deperdit {margin-top: 25px; padding-left: 0;}
    #root .main .syntheseDatas .syntheseTAB {margin: 30px 0 40px 0;}
    #root .main .syntheseDatas .syntheseTAB table.syntTAB tbody tr td.details img {max-height: 100px;}

    table, thead, tbody, th, td, tr {position: relative; display: block;}
    table thead tr {position: absolute; top: -9999px; left: -9999px;}
    table tbody tr {margin: 2px 0 0 0;}
    table tbody tr td {border-bottom: 0 !important; border-left: 0 !important; border-right: 0 !important;}
    table tbody tr:last-child {margin-bottom: 0; border-bottom: 0 !important;}
    table tbody tr td {position: relative; width: 100% !important; padding-left: 55% !important; white-space: normal; text-align:left;}
    table tbody tr td:before {content: attr(data-title); width: 100%; position: absolute; top: 6px; left: 6px; padding: 5px 100px 5px 0; text-align:left; font-weight: bold; }

    table.etudeDATAS tbody tr {

      &.underTR td:before {
        padding-left: 30px;
      }

      td.versionnning, td.nom, td.reference, td.client, td.date, td.statut, td.version { min-height: 35px; }

      td.email_etude:before,
      td.email_rapport:before,
      td.edit_etude:before,
      td.edit_rapport:before,
      td.version:before {display: none;}

      td.email_rapport,
      td.edit_etude,
      td.edit_rapport,
      td.versionnning {border: none !important; padding: 0 !important; background: white !important;}

      td.email_rapport,
      td.email_etude,
      td.version {padding: 0 !important; background: white !important;}

      td.versionning {display: none;}
      td.email_etude {padding-top: 30px !important;}
      td.email_etude a {position: absolute !important; bottom: 5px; left: 8px;}
      td.email_etude a span {display: block !important; left: 60px; margin-top: 4px;}
      td.email_rapport a {position: absolute !important; bottom: 5px; right: 36px;}
      td.email_rapport a span {display: block !important; right: 58px; margin-top: 4px;}
      td.edit_etude button {position: absolute !important; bottom: -2px; left: 46px;}
      td.edit_rapport a, td.edit_rapport a {position: absolute !important; bottom: -2px; right: 8px;}
      td.delete button.btn_delete {display: block !important;}

      td.version {
        display: block !important;
        padding-top: 0 !important; padding-bottom: 0 !important;
        border-top: 0 !important; border-bottom: 0 !important;
        background: white !important;

        button.btn_version {
          position: absolute; top: -22px; left: 7px;
          margin: 0 !important;
          width: auto !important;

          i {display: none !important;}
          span {display: block !important;}
        }
      }

      td.delete {
        padding-top: 0 !important; padding-bottom: 0 !important;
        border-top: 0 !important; border-bottom: 0 !important;
        background: white !important;

        button.btn_delete {
          position: absolute; top: 2px; left: calc(100% - 150px + 7px);
          margin: 0 !important;
          width: auto !important;

          i {display: none !important;}
          span {display: block !important;}
        }
      }

      &.underTR td.nom,
      &.underTR td.reference,
      &.underTR td.client,
      &.underTR td.date,
      &.underTR td.statut {padding-top: 10px !important; padding-bottom: 10px !important;}
    }

    table.accessTAB tbody tr td {
      padding-top: 7px !important; padding-bottom: 7px !important;

      &.total {border-bottom: 1px solid var(--saumon) !important;}

      &.delete {
        padding-top: 45px !important;
        background: white !important;

        button.btn_delete {
          position: absolute !important; bottom: 22px; right: 6px;

          i {display: none !important;}
          span {display: block !important;}
        }
      }
    }

    table.syntTAB tbody tr td {
      border: 1px solid var(--saumon);

      &.details {
        background: white !important;
        img {width: auto !important;}
      }

      &.select {
        //padding-top: 40px !important;
        //background: white !important;
        &:before {width: 50%;}
        button.btn_select {margin: 0 !important}
        /*button {
          position: absolute; top: 0; left: 0; z-index: 1;
          border: none !important;
          i {display: none !important;}
          span {display: inline-block !important;}
        }*/
      }
    }

    .imageTAB {display: none !important;}

    div {flex-direction: column;}
    div table {width: 100%;}

    .tab_sousTotal {border-top: 1px solid var(--saumon);}

    .modalSaveProject {
      .clientList {
        max-height: 280px;
      }
    }

    #root .pageAide .main {
      //background: none;
    }

    #root .pageSatisfaction .row .col:nth-child(2) {
      display: none;
    }

    #root .main .contentProduit .leContenu p.titre_produit {
      margin-top: 20px;
    }

    #root .main .contentProduit .sidebar .imgProduit img {
      max-width: 300px;
    }

    #root .main .donneesGEO .form .orientation {
      display: none;
    }
    #root .main .donneesGEO .form .row.row_cool_config_header {
      display: none;
    }

    .mobileOnly {
      display: block;
    }

    .modalSaveProject .space {
      margin-top: 0px !important;
    }
  }

  // Tablette < 768px
  @include taille(tablette768) {
    #root .main .row.barreProduit {margin: 20px;}

    #root .main .contentProduit {
      margin: 0 20px 0 20px;
    }

    #root .main .row.devis {padding: 10px;}
    #root .main .row.devis .devisTVA .produitTAB {
      margin-top: 10px;

      .imageTAB {
        padding: 15px; border-right: none;
        img {max-width: 150px;}
      }

      .tarifTAB {
        padding: 10px 0;
        em {margin-top: 0;}
      }
    }

    #root .main .donneesHAB .contentRubrique .row {
      .maison_mitoyennete, .maison_type {flex-basis: 100%;}
    }

    #root .main .synthese {
      margin: 20px;

      .choixDOC {margin-bottom: 10px; padding-right: 10px; border-right: 0;}
      .choixDOC em {margin-top: 20px;}
      .choixDOC form button {margin: 20px 0;}
      .downloadDOC {padding-left: 0; padding-bottom: 30px;}
    }

    #root .pageAide .main .row.blocAide .sidebar {
      flex-direction: row;
      margin-left: 0; padding: 0 20px 20px 20px;

      nav.insideMenu {padding-left: 20px;}
    }

    #root .pageAide .main .row.blocAide .contentAide {padding: 0 20px; flex-basis: 100%;}
    #root .pageAide .main .row.blocAide .contentAide.tuto {padding: 0 20px; flex-basis: 100%; height: 100%;}
    #root .pageAide .main .row.blocAide .contentAide video {
      width: 100%;
    }

    #root .main .row.contentProduit .sidebar {
      justify-content: center !important;
      margin-left: 0;

      nav.produitMenu {
        @include reset_margin;
        width: 100%;

        ul {width: 100%; align-items: center; padding-top: 15px;}
        ul li {width: 100%; margin: 0;}
        ul li span {width: 100%; border-bottom: 1px solid var(--saumon) !important; padding: 5px;}
        ul li:last-child span {border-bottom: 0 !important;}

        ul li.active span, ul li:hover span, ul li:active span {background: var(--saumon); color: white;}
      }
    }

    #root .pageHome .main .row.blocHome {
      width: 90%;
      top: 50px;

      .blocProjipac {
        top: -70px;
        img {height: 60px;}
        p {margin-right: -10px; padding-top: 20px; font-size: 2rem;}
      }

      .blocProjipacPacific {
        top: -150px;
        img {height: 60px; width: 60px}
      }

      p.BlocHomeTexte {font-size: 0.875rem; line-height: 130%; text-align: center; padding: 15px;}
      .blocHomeTitre {padding: 10px;}
      .blocHomeTitre a.btn {padding: 10px;}
    }

    #root .pageHomeThermor .main .row.blocHome {
      .blocProjipac {
        top: -257px;
        img {height: auto;}
      }
    }
  }
  
  @media (max-height:1368px) and (max-width:1024px) {
    #root .info {
      margin-left: 10%;
    }
  }

  @media (max-height:1368px) and (max-width:992px) {
    #root .info/*, #root .pageHome*/ {
      display: block;
    }
    #root .info {
     // position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      max-width: 100%;
      flex:1;
    }
  }
  @media (max-height:1368px) and (max-width:992px) {
    #root .pageChoix .main.container-fluid {
      flex-direction: column;
      min-height: 100%;
    }
  }
  @media (max-height:1368px) and (max-width:992px) {
    #root .pageChoix/*, #root .pageHome*/ {
      display: block;
    }
    #root .pageChoix .main .row.choixProjet {
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      max-width: 100%;
      flex:1;

      .blocProjipac {
        padding: 0;
      }
    }
  }

  @media (max-height:600px){
    #root .info/*, #root .pageHome*/ {
      display: block;
    }
    #root .info {
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      max-width: 100%;
      flex:1;
    }
  }

  @media (max-width:600px) {
    #root .info/*, #root .pageHome*/ {
      display: block;
    }
    #root .info {
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      max-width: 100%;
      flex:1;
    }
  }

  @media (max-width:600px) {
    #root .pageChoix/*, #root .pageHome*/ {
      display: block;
    }
    #root .pageChoix .main .row.choixProjet {
     // position: absolute;
      display: block;
      top: 0;
      bottom: -300px;
      left: 0;
      right: 0;
      margin: 0;
      max-width: 100%;
      flex:1;

      .blocProjipac {
        padding: 0;
      }
    }
  }

  @media (max-height:600px){
    #root .pageChoix/*, #root .pageHome*/ {
      display: block;
    }
    #root .pageChoix .main .row.choixProjet {
      //position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      max-width: 100%;
      flex:1;

      .blocProjipac {
        padding: 0;
      }
    }
  }

  @media (max-height:600px) {
    #root .pageChoix .main.container-fluid {
      flex-direction: column;
      min-height: 100%;
    }
  }

  // Tablette < 576px
  @include taille(mobile576) {
    #root .header.container-fluid.withoutBreadcrumb .logoAtlantic {display: none;}

    #root .pageHome, #root .pageChoix {background: url(../img/background_576.jpg) no-repeat; background-position: center; background-size: cover;}
    #root .pageHomeThermor, #root .pageChoix {background: url(../img/background_thermor_576.jpg) no-repeat; background-position: center; background-size: cover;}
    #root .pageHomePacific, #root .pageChoix {background-image: none}
    #root .pageChoix .main .row.choixProjet {max-width: 100%; margin: 0;}

    #root .main .donneesACC .contentRubrique .allProduits .blocProduit {margin-bottom: 20px; padding-right: 0;}
    #root .main .donneesACC .contentRubrique nav.navProduits {
      ul {@include goFlex(column, wrap, flex-start, center);}
      ul li {margin: 0; border-right: 0; border-bottom: 1px solid var(--saumon); width: 100%;}
      ul li a {}
    }

    #root .footer .row button.btn.navPrev {
      font-size: 0.750rem;
      i {margin-right: 5px;}
    }
    #root .footer .row button.btn.navNext {
      font-size: 0.750rem;
      i {margin-left: 5px;}
    }
    #root .footer .row button.btn.enregistrer {
      font-size: 0.750rem; padding: 15px;
      &:hover, &:active {letter-spacing: 1px;}
    }

    #root .main .row.etude .rechETUDE form .blocForm input#data_search {max-width: 100%;}

    //#root .pageHome .main .row.blocHome {margin-top: 70px;}
  }

  // Mobile < 440px
  @include taille(mobile440) {
    .btn_std.energyPrices {
      position: relative;
      display: flex;
      align-self: center;
    }

    #root .header .menuBarre .logoProjipac {
      // p {top: 10px;}
    }

    #root .pageChoix .main .row.choixProjet {
      max-width: 100%;
      .blocProjipac {padding: 15px 0;}
      .row.blocProjet {padding: 10px 0 40px 0;}
      .row.blocProjet .nouveauP, .row.blocProjet .simulationP {margin: 5px 5px; padding: 20px; width: 200px}
    }

    #root .main .choixSimulation {
      margin: 0 auto 10px 0; padding-right: 10px;
      p {width: 100%; text-align: center;}
      form {flex-direction: row; margin-left: 10px; width: 100%;}
      form .pretty.p-switch {width: 50%; justify-content: center;}
    }

    #root .main .choixSticker .stickerChaud,
    #root .main .choixSticker .stickerFroid {padding: 6px 20px; width: 50%;}

    #root .main .donneesDEP .contentRubrique .estimation .besoin .row {
      .infosBesoin, .infosBesoin1 {
        em {font-size: 1.125rem;}
      }
    }

    #root .pageAide .main .row.blocAide .contentAide p {font-size: 1rem;}
    #root .pageAide .main .row.blocAide .sidebar {
      flex-direction: column; align-items: center;

      nav.insideMenu {
        @include reset_margin;
        width: 100%;

        ul {width: 100%; align-items: center; padding-top: 15px;}
        ul li {width: 100%; margin: 0;}
        ul li a {width: 100%; border-bottom: 1px solid var(--saumon) !important; padding: 5px;}
        ul li:last-child a {border-bottom: 0 !important;}

        ul li.active a, ul li:hover a, ul li:active a {background: var(--saumon); color: white;}
      }
    }

    #root .main .devis .choixTVA form {width: 100%;}
    #root .main .devis .choixTVA form .blocForm select#data_tva {min-width: 100%;}

    #root .main .synthese .choixDOC form button {font-size: 1rem;}

    #root .main .etude {padding: 10px;}
    #root .main .etude {padding: 10px;}
    table tbody tr {display: flex; flex-direction: column;}
    table tbody tr {display: flex; flex-direction: column;}
    table.syntTAB tbody tr td {padding-left: 75% !important;}
    table.prodTAB tbody tr td {padding-left: 60% !important;}
    table.accessTAB tbody tr td {padding-left: 60% !important;}
    table tbody tr td:before {width: 100%;}
  }
}