// Nouveau code

.breakWord {
  word-break: break-word
}

button.btn_std {
  @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
  color: white;
  text-transform: uppercase;
  background: var(--saumonAlt);
  padding: 10px 20px;

  &:hover,
  &:active {
    background: var(--ocreAlt);
  }
}

button.energyPrices {
  position: absolute;
  right: 0;
  z-index: 1
}

.modalSaveProject {
  .energyTab {
    margin-top: 30px;
    
    .header {
      background: var(--pdtLib);
      @include sassRem(normal, 1.125rem, var(--typo_bold), 130%);
      color: white;
      text-align: center;
      margin-bottom: 5px;

      div {
        padding: 10px;
      }
    }

    .blocForm {
      padding-right: 0px !important;
    }
  }
}

.loginInterne {
  color: black;
  text-decoration: underline;
  font-style: italic;
}

.loadingBarWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  z-index: 9999;
}

.installBtn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 52px;
  z-index: 1000;
  background-color: #7d2008;
  font-weight: 800;
  color: white;
}

.blocForm {
  display: inline-block;
}

button.help {
  position: absolute;
  right: 10px;
  display: inline-block;
  background-color: var(--saumonAlt);
  mask: url(../img/picto-info.png) no-repeat;
  mask-size: cover;
  background-size: 20px 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: 8px;

  &.cold {
    background-color: $froid;
  }

  &.acc {
    background-color: black;
    position: relative;
    right: auto;
    margin-left: 0;

    &.thermor {
      background-color: var(--saumonAlt);
    }
  }
}

.mobileOnly {
  display: none;
}

.noProduct {
  margin: 50px;
}

.preload {
  visibility: hidden !important;
  position: absolute;
}

#root .pageSatisfaction {
  padding-bottom: 100px;

  .content.main.container-fluid {
    .row .col:nth-child(1) {
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
    }

    h2 {
    }
    .description {
      margin-top: 50px;
      font-weight: 800;
    }

    textarea {
      border: 1px solid #495057;
      background: white;
      height: 100px;
    }
    /*textarea+label.placeholder {
      width: 100%;
    }*/

    button {
      background-color: var(--saumon);
      color: white;
      text-align: center;
      margin: auto;
      margin-top: 15px;
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 5px;
      &:hover {
        background-color: #7d2008;
      }
    }

    img.visuel {
      width: 100%;
      margin-top: 173px;
    }
  }

  .blocForm {
    margin: 10px 0;

    .pretty {
      input[type='radio']:checked + .state label:after {
        background-color: var(--saumonAlt) !important;
      }

      .state {
        label:before {
          border-color: var(--saumon);
        }
      }
    }
  }
}

#root form.coldMode {
  .pretty {
    input[type='radio']:checked + .state label:after {
      background-color: #60aff4 !important;
    }

    .state {
      label:before {
        border-color: #60aff4;
      }
    }
  }
}

.infosPlus {
  margin: 20px;

  .row {
    flex-direction: initial;

    .col:last-child {
      text-align: right;
    }
  }

  .col {
    padding: 5px;
    border: 1px solid #bdc3c7;

    &:first-child {
      font-weight: bold;
    }
  }

  span {
    color: var(--saumon);
  }
}

.chartNote {
  padding: 0 50px;
}

input,
select,
textarea {
  &:disabled {
    background-color: #f6f6f6 !important;
    border-color: #d4d2d2 !important;
    &::placeholder {
      color: #d4d2d2;
    }
  }
  &:disabled ~ span.unit,
  &:disabled + label.placeholder {
    color: #d4d2d2;
  }
  ~ &.withUnit {
    padding-right: 25px !important;
  }
}

span.unit {
  position: absolute;
  right: 5px;
  line-height: 36px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;

  &.select {
    right: 25px;
  }
}

label.placeholder {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
  margin: 0;
  padding: 0;
  padding-right: 28px;
  word-break: break-all;

  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 6px;
  z-index: 5;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus,
input.not-empty,
select:focus,
select.not-empty,
textarea:focus,
textarea.not-empty {
  padding-top: 10px !important;

  & ~ span.unit {
    top: 6px !important;
  }
}

input:focus + label.placeholder,
input.not-empty + label.placeholder,
select:focus + label.placeholder,
select.not-empty + label.placeholder,
textarea:focus + label.placeholder,
textarea.not-empty + label.placeholder {
  top: -1px;
  font-size: 12px;
  color: var(--saumon);
  height: 16px;
}

input:disabled,
select:disabled,
textarea:disabled {
  color: #d4d2d2;

  & + label.placeholder {
    color: #d4d2d2;
  }
}

.sweetAlertCustom {
  font-size: 0.825em;

  .btn-danger {
    color: #fff;
    background-color: #d9534f;

    &:hover {
      color: #fff;
      background-color: #c9302c;
    }
  }
}

.chartRow {
  width: 100%;

  .chart {
    width: 100%;
    min-height: 350px;
  }
}

.versionApp {
  position: absolute;
  left: 0;
  margin-left: 10px;
  color: #bdc3c7;
  font-size: 0.725em;
}

#root .main.container-fluid.myAccountInfos {
  margin-top: 70px;

  .leftCol {
    padding-right: 30px;
  }

  .note {
    margin: 4% 0;
  }

  .logo {
    background: #f2f2f2;
    padding: 20px;
    margin-bottom: 20px;
  }

  .blocForm {
    padding-right: 30px;
  }

  input,
  select,
  textarea {
    border-color: var(--saumon);
    width: 100%;
  }
  input,
  select {
    padding-bottom: 0px;
    height: 36px;
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    background-color: white;
    border: 1px solid var(--saumon);
    display: inline-block;
    cursor: pointer; /* "hand" cursor */
    width: calc(100%);
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    text-align: center;
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    background-color: white;
    border: 1px solid var(--saumon);
    //outline: 1px dotted #000;
    //outline: -webkit-focus-ring-color auto 5px;
  }
}

.header.withoutBreadcrumb {
  .logoAtlantic {
    margin-left: auto;
  }
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.8;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  input,
  select {
    height: 36px;
    margin-right: 30px;
    border-color: var(--saumon);
  }

  button {
    &.enregistrer,
    &.cancel,
    &.search {
      @include sassRem(normal, 0.875rem, var(--typo_bold), 100%);
      color: white;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 11px 40px;
      background: var(--ocre);
      border-radius: 0;

      &:hover,
      &:active {
        span {
          background: url(../img/fleche-gauche-on.svg) no-repeat;
        }
        background: white;
        color: var(--ocre);
        text-decoration: none;
        letter-spacing: 2px;
      }
    }

    &.cancel {
      background: lightgray;
    }

    &.search {
      min-width: calc(100% - 30px);
      margin: 0 30px 10px 0;
    }
  }
}

.modalSaveProject,
.modalSatisfaction {
  .btn_close {
    display: block;
    @include reset_margin;
    @include sassRem(normal, 1.25rem, var(--typo_bold), 100%);
    color: white;
    margin-left: auto;
    padding: 0 12px;
    height: 40px;
    background: var(--ocre);

    &:hover,
    &:active {
      background: var(--saumon);
    }

    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 0.2rem;
  }
}

.modalSaveProject {
  .space {
    width: 100%; 
    margin-top: 48px;

    &.spacex2 {
      margin-top: 96px;
    }
  }

  .blocForm {
    padding-right: 30px !important;
  }

  input,
  select,
  textarea {
    border-color: var(--saumon) !important;
    width: 100% !important;
  }
  input,
  select {
    padding-bottom: 0px !important;
    height: 36px !important;
  }

  p {
    font-style: normal;
    font-size: 1.4rem;
    font-family: var(--typo_light);
    line-height: 100%;
    color: var(--saumon);
    text-transform: uppercase;
    padding: 15px 0;
  }

  .gray {
    color: #495057;
  }

  .clientList {
    margin: 20px 0;
    max-height: 120px;
    overflow: scroll;
  }

  .client {
    border-bottom: 1px solid lightgray;
    padding: 5px;
    margin-right: 30px;
    cursor: pointer;

    &:nth-child(odd) {
      background-color: #f3f3f3;
    }

    .col:last-child {
      text-align: right;
    }

    &:last-child {
      border: none;
    }
  }
}

.btn_delete {
  i {
    display: inline-block;
    background: url(../img/picto-delete.svg) no-repeat;
    width: 17px;
    height: 21px;
    margin-right: 6px;
    transition: none;
  }

  &.file {
    position: absolute;
    right: 0;
    top: 0;
    margin: 7px;
  }
}

.releveChaudiere {
  .releveChaudiereRadio {
    text-align: center;
    width: 100%;
  }
}

.modal-xl {
  min-width: 95%;
}

.blocForm {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;

  input,
  select,
  textarea {
    margin-right: 30px;
    padding-left: 10px;
    width: 100%;

    &.error {
      border: 2px solid red !important;
    }
  }

  textarea.comment {
    height: 86px;
  }
}

.quote_comment {
  height: 200px !important;
}

.pretty .state label:before,
.pretty .state label:after {
  width: 20px;
  height: 20px;
}

#root .main.hpchoice-step {
  .row.donneesGEO {
    .form {
      input,
      select {
        margin-bottom: 10px;
      }
    }

    .choixIsolation {
      width: auto;

      .pretty {
        margin: 10px 30px 10px 0;
        input[type='radio']:checked + .state label:after {
          background: var(--saumonAlt) !important;
        }
      }

      .state {
        &:before {
          //top: 0 !important;
          //left: -2px !important;
          //margin-top: 2px;
          background: $gris;
        }

        label {
          position: relative;
          @include sassRem(normal, 1.1rem, var(--typo_light), 100%);

          &:before {
            //margin-top: -1px;
            border-color: var(--saumon);
          }
          &:after {
            //margin-top: -1px;
          }
        }
      }
    }
  }

  .typePompe {
    @include goFlex(row, wrap, flex-start, flex-start);
    @include sassRem(normal, 1rem, var(--typo_regular), 120%);
    color: var(--saumon);
    text-align: left;
    text-transform: uppercase;

    .aerothermie,
    .geothermie,
    .hybride {
      @include goFlex(column, wrap, center, center);
      @include reset_margin;
      @include sassRem(normal, 0.75rem, var(--typo_regular), 120%);
      color: $gris_moyen;
      text-align: center;
      margin: 0 5px;
      padding: 10px;
      height: 100%;

      span {
        display: block;
        background: transparent;
        width: 100px;
        height: 80px;
        margin-bottom: 4px;
      }

      &:hover,
      &:active,
      &.active {
        color: var(--saumon);
      }
    }

    .aerothermie {
      span {
        background-color: $gris_moyen;
        mask: url(../img/aerothermie-off.svg) no-repeat center center;
      }

      &:hover,
      &:active,
      &.active {
        span {
          background-color: var(--saumonAlt);
          mask: url(../img/aerothermie-on.svg) no-repeat center center;
        }
      }
    }

    .geothermie {
      span {
        background-color: $gris_moyen;
        mask: url(../img/geothermie-off.svg) no-repeat center center;
      }

      &:hover,
      &:active,
      &.active {
        span {
          background-color: var(--saumonAlt);
          mask: url(../img/geothermie-on.svg) no-repeat center center;
        }
      }
    }

    .hybride {
      span {
        background: url(../img/solutions-hybrides-off.svg) no-repeat center center; 
      }

      &:hover,
      &:active,
      &.active {
        span {
          background: url(../img/solutions-hybrides-on.svg) no-repeat center center;
        }
      }
    }

    .hybride.thermor {
      span {
        background: url(../img/solutions-hybrides-thermor-off.svg) no-repeat center center;
      }
      &:hover, &:active, &.active {
        span {
          background: url(../img/solutions-hybrides-thermor-on.svg) no-repeat center center;
        }
      }
    }
    
    .hybride.pacific {
      span {
        background: url(../img/solutions-hybrides-thermor-off.svg) no-repeat center center;
      }
      &:hover, &:active, &.active {
        span {
          background: url(../img/solutions-hybrides-pacific-on.svg) no-repeat center center;
        }
      }
    }
  }

  .releveChaudiere {
    @include goFlex(row, wrap, flex-start, center);
  }
}

.changelog,
.faq {
  margin-top: 10px;
  text-align: left;

  .version {
    font-weight: bold;
  }

  ul {
    padding-left: 30px;
  }
}

.faq {
  margin-bottom: 70px;

  ul {
    list-style: none;
  }

  .question {
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
  }
  .answer {
    margin: 20px 0px;
  }
}

// Walter
.row.barreProduit {
  @include goFlex(row, wrap, flex-start, flex-start);
  margin: 50px;

  .produitLibelle,
  .produitCode,
  .produitPrix {
    @include goFlex(row, wrap, center, center);
    background: var(--saumon);
    height: 40px;

    p {
      padding: 0 10px;
      @include sassRem(normal, 1.125rem, var(--typo_bold), 130%);
      color: white;
      text-align: center;
    }

    button.btn_close {
      display: block;
      @include reset_margin;
      @include sassRem(normal, 1.25rem, var(--typo_bold), 100%);
      color: white;
      margin-left: auto;
      padding: 0 12px;
      height: 40px;
      background: var(--ocre);

      &:hover,
      &:active {
        background: var(--saumon);
      }
    }
  }
  .produitCode {
    background: rgba(var(--saumon-r), var(--saumon-g), var(--saumon-b), 0.85);
  }
  .produitPrix {
    background: rgba(var(--saumon-r), var(--saumon-g), var(--saumon-b), 0.7);
    p {
      margin-left: auto;
      color: var(--ocre);
    }
  }
}

.row.contentProduit {
  @include goFlex(row, wrap, flex-start, flex-start);

  .sidebar {
    @include goFlex(column, wrap, flex-start, flex-start);
    margin-left: 70px;
    padding-bottom: 20px;
    width: 100%;

    nav.produitMenu {
      margin-bottom: 20px;
      ul {
        @include resetUL;
        @include goFlex(column, wrap, flex-start, flex-start);
        font-size: 0;

        li {
          margin: 5px 0;
          span {
            cursor: pointer;
            width: 100%;
            @include goFlex(row, wrap, center, center);
            @include sassRem(normal, 0.938rem, var(--typo_light), 100%);
            color: black;
            text-decoration: none;
            border-bottom: 5px solid transparent;
            transition: 0.3s;
          }
          &:hover span,
          &:active span,
          &.active span {
            border-bottom: 5px solid var(--saumon);
            text-decoration: none;
            transition: 0.3s;
          }
        }
      }
    }

    .imgProduit {
      text-align: center;
      padding-right: 20px;
    }
    .imgProduit img {
      @include responsive;
    }
    .imgProduit img.pictoNRJ {
      width: 70% !important;
      margin: 0 20px 20px 20px;
    }
  }

  .leContenu {
    @include goFlex(column, wrap, flex-start, flex-start);
    flex: 1;
    padding: 40px 0;

    div.contenu {
      width: 100%;
      margin-top: 10px;
    }
    strong {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }

    p {
      width: 100%;
      margin-bottom: 10px;
      @include sassRem(normal, 1rem, var(--typo_light), 100%);
      color: black;

      &.titre_produit {
        @include sassRem(normal, 1.375rem, var(--typo_bold), 100%);
        color: var(--saumon);
      }

      i {
        display: inline-block;
        background: url(../img/puce.svg) no-repeat;
        width: 12px;
        height: 12px;
        margin-right: 2px;
      }
    }
  }
}

#root .main .coldMode {
  .titreRubrique p {
    color: var(--bleu);
    border-color: var(--bleu);
  }

  .donneesGEO .form select,
  .donneesGEO .form input {
    border-color: var(--bleu);
  }

  .donneesGEO .form {
    input:focus + label.placeholder,
    input.not-empty + label.placeholder,
    select:focus + label.placeholder,
    select.not-empty + label.placeholder,
    textarea:focus + label.placeholder,
    textarea.not-empty + label.placeholder {
      color: var(--bleu);
    }
  }

  .donneesGEO .form {
    input:disabled,
    select:disabled,
    textarea:disabled {
      color: inherit !important;
      border-color: var(--bleu) !important;
      //background-color: white !important;
    }
  }

  .inline {
    display: inline-block;
    margin-left: 10px;
  }

  .currency {
    position: relative;
    top: 9px;
    color: #60aff4;
    font-weight: 200;
    font-size: 1.6em;
    padding-right: 40px;
    transform-origin: 0 0;
    transform: rotate(-20deg);
    width: 35px;

    &.bold {
      top: 15px;
      font-size: 2.2em;
      font-weight: 900;
      padding-right: 50px;
    }
  }
}

#root .main .heatMode {
  .titreRubrique p {
    border-color: --saumon;
  }
}

// Spinner
#no-freeze-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.1s linear;
  opacity: 0;
  height: 100vh;
  transform: scale(10);
  z-index: -1;
  background: $dark;
}
.no-freeze-spinner #no-freeze-spinner {
  z-index: 9999;
  opacity: 0.5;
  transform: scale(1);
}
#no-freeze-spinner > div > div {
  animation-play-state: paused;
  border: 5px solid rgba(200, 200, 200, 1);
  border-radius: 50%;
  animation: rotate 1s infinite linear;
  border-left-color: rgba(200, 200, 200, 0.4);
  width: 100%;
  height: 100%;
}
#no-freeze-spinner > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  box-shadow: 0 0 0 2000px $dark;
  width: 60px;
  height: 60px;
  padding: 5px;
}
.no-freeze-spinner #no-freeze-spinner div div {
  animation-play-state: running;
}
@keyframes loadicons {
  0% {
    transform: scale(0) translate3d(0, 0, 0);
  }
  11% {
    transform: scale(1.2) translate3d(0, 0, 0);
  }
  22% {
    transform: scale(1) translate3d(0, 0, 0);
  }
  33% {
    transform: scale(0) translate3d(0, 0, 0);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.no-freeze-spinner #no-freeze-spinner i {
  font-size: 24px;
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 1;
  transform: scale(0) translate3d(0, 0, 0);
}
.no-freeze-spinner #no-freeze-spinner i:nth-of-type(1) {
  animation: loadicons 3s infinite ease-in-out;
}
.no-freeze-spinner #no-freeze-spinner i:nth-of-type(2) {
  animation: loadicons 3s 1s infinite ease-in-out;
}
.no-freeze-spinner #no-freeze-spinner i:nth-of-type(3) {
  animation: loadicons 3s 2s infinite ease-in-out;
}
